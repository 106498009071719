import React, { useState, useRef, useLayoutEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
// import { useHistory } from "react-router";
import moment from "moment";
import ApiEndPoint from "../../../utils/apiEndPoints";
import loader from "../../../assets/images/logo.png";
import Google from "../../../assets/images/google.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactPlayer from "react-player/lazy";
import Select from "@mui/material/Select";
import { useEffect, useContext } from "react";
import {
  VideoListContext,
  ShowCreatePlayListContext,
  UserloggedContext,
  UserPlayListContext,
  SuggestedVideoListContext,
  PlayNextContext,
  MusicTrackContext,
  MusicTracksContext,
  PlayingShuffledAlbumIdContext,
  PlayingAlbumIdContext,
  MusicPlayerVisibilityContext,
  StopVideoTimeContext,
  CurrentTrackPlayingContext,
} from "../../Context/UserContext";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import { AuthContext } from "../../Context/context";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import Button from "@material-ui/core/Button";
import {
  AllImages,
  googleClientId,
  VideoPlaylistShareUrl,
  GLOBAL_URL,
  RemoveDuplicates,
  getImageNameFromResolution,
  emojiRegex,
  formatTimeForTimeStamp,
} from "../../../utils/constants";
import { useTheme, useMediaQuery } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { RWebShare } from "react-web-share";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import { FaBackward, FaForward } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
const clientId = googleClientId;

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";



function VideoPlayPage() {
  const playerRef = useRef(null);
  const location = useLocation();
  // const { item } = location.state;
  const { vidId } = useParams();
  const [showLoader, setShowLoader] = useState(true);
  const [stopTime, setStopTime] = useContext(StopVideoTimeContext);
  const [videoList, setVideoList] = useContext(VideoListContext);
  const { currentTrackPlaying, setCurrentTrackPlaying } = useContext(CurrentTrackPlayingContext);
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePauseVideo = () => {
      setIsPlaying(false);
  };

  // const handlePlayAudioRef = () => {
  //   setIsPlaying(true);
  // };


  const handlePlayingVideo = () => {
    setIsPlaying(true);
    // handlePauseAudioRef()
    handlePauseAudio()
        setTimeout(() => {
          setStopTime(null);
          setSingleTrack([]);
          setTrack([]);
          setPlayNextTrack([]);
          setPlayingShuffledAlbumId(null);
          setPlayingAlbumId(null);
          setTrack([]);
    }, 1000);
    if (isMusicPlayerVisible) {
      setIsMusicPlayerVisible(false);
    }
  };
  
  const handlePausingVideo = () => {
  };

  



  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );
  const [suggestedVideoList, setSuggestedVideoList] = useContext(
    SuggestedVideoListContext
  );
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [userPlayList, setUserPlayList] = useState([]);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [isLoading, setIsLoading] = useState(false);
  const { signInFun, signOutFun } = React.useContext(AuthContext);
  const [documentData, setDocumentData] = useState([]);
  const [documentDataAudio, setDocumentDataAudio] = useState([]);
  const [documentDataPad, setDocumentDataPad] = useState([]);
  const [documentDataPatra, setDocumentDataPatra] = useState([]);
  const [documentDataEbook, setDocumentDataEbook] = useState([]);
  const [documentDataTranscript, setDocumentDataTranscript] = useState([]);
  const [playlistName, setPlaylistName] = useState("");
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [isValidNote, setIsValidNote] = useState(false);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(MusicPlayerVisibilityContext);




  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);
  const [createNoteBtnLding, setNoteBtnLding] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [video, setVideo] = useState();
  const [item, setItem] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(0);
  const [scrollHashMap, setScrollHashMap] = useState(true);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState(vidId);
  const [scrollLast, setScrollLast] = useState(true);
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [noteTitle, setNoteTitle] = useState("Shabdarth");
  const [note, setNote] = useState(null);
  const [startTime, setStartTime] = useState("00:00:00");
  const [endTime, setEndTime] = useState(null);
  // const history = useHistory()
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const dialogContentRef = useRef(null);

  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const [fileDownloadLoaderType, setFileDownloadLoaderType] = useState(null);

  // STOP THE MUSIC PLAYER  STATE
  const { track, setTrack } = useContext(MusicTracksContext);
  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);

  const handlePlaylistScroll = () => {
    const { current } = dialogContentRef;
    if (
      current &&
      current.scrollTop + current.clientHeight >= current.scrollHeight &&
      scrollHashMap
    ) {
      fetchMoreUserPlaylistData();
    }
  };
  const openModal = () => {
    if (auth) {
      setIsOpen(true);
    } else {
      takeOtherSubcription();
    }
  };

  const handlePauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };
  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };
  const setTitleArtWork = () => {  
    document.title = item?.videoTitle
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: item?.videoTitle,
          artist: item?.speaker,
          artwork: [
            { src: item?.thumbnails, sizes: '512x512', type: 'image/png' },
          ]
        });
        navigator.mediaSession.setActionHandler('pause', () => {
          handlePauseAudio();
        });
  
        navigator.mediaSession.setActionHandler('play', () => {
          handlePlayAudio()
        });
      }  
  };

  const removeTitleArtWork = () => {  
    document.title = 'satshrut';
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = null; 
      navigator.mediaSession.setActionHandler('pause', null); 
      navigator.mediaSession.setActionHandler('play', null);  
    }
  };
  


  const audioRef = useRef(null);

  useEffect(() => {
    const audioElement = audioRef?.current;
    const handlePlayAudioRef = () => {
      handlePauseVideo()
      setTitleArtWork()
    };

    const handlePauseAudioRef = () => {
      removeTitleArtWork()
    };

    if (audioElement) {
      audioElement.addEventListener('play', handlePlayAudioRef);
      audioElement.addEventListener('pause', handlePauseAudioRef);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('play', handlePlayAudioRef);
        audioElement.removeEventListener('pause', handlePauseAudioRef);
      }
    };
  }, [item]);



  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  const openSaveInfoModal = () => {
    if (auth) {
      setShowSaveInfo(true);
      setEndTime(getTimeStamp());
    } else {
      takeOtherSubcription();
    }
  };
  const closeSaveInfoModal = () => {
    setShowSaveInfo(false);
  };
  useEffect(() => {
    fetchVideo(vidId);
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  async function fetchSuggestedList(videoTitle) {
    // setIsLoading(true)
    try {
      const params = {
        pageNo: 0,
        searchValue: videoTitle,
        pageSize: 6,
      };

      const { data } = await apiCall(
        "POST",
        `${ApiEndPoint.SUGGESTEDVIDEO}`,
        params
      );
      if (data.length > 0) {
        setIsLoading(false);
        setSuggestedVideoList(data);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }

  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };

  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex; // your emoji regex here;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 20) {
      return false;
    } else return true;
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;

    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const handleNoteChange = (event) => {
    const inputValue = event.target.value;

    if (validateInput(inputValue, true)) {
      setNote(inputValue);
      setIsValidNote(true);
    } else {
      setIsValidNote(false);
    }
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const validateNoteForm = () => {
    if (note == "" || noteTitle == "") {
      return false;
    }
    return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
          contentType:'VIDEO'
        };
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
            openModal();
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setPlaylistBtnLding(false);
        }
      } else {
        setIsValidPlaylistName(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  const handleSubmitNote = async (event) => {
    const valid = validateNoteForm();
    if (auth) {
      if (valid) {
        const params = {
          videoTitle: video.videoTitle,
          videoUrl: window.location.href,
          startTimeFrame: startTime,
          endTimeFrame: endTime,
          contentId: video.id,
          incidentType: noteTitle,
          incidentWord: note,
        };
        setNoteBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.SAVACREATENOTE}`,
            params
          );
          if (data) {
            setShowSaveInfo(false);

            setNote(null);
            setRefresh(!refresh);
            setNoteBtnLding(false);
            toast.success("Saved successfully");
          } else {
            setRefresh(!refresh);
       
            setRefresh(!refresh);
            setNoteBtnLding(false);
            toast.error("Something went wrong");
          }
        } catch (err) {
          setShowSaveInfo(false);
          setRefresh(!refresh);
          setNoteBtnLding(false);
          toast.error("Something went wrong");
        }
      } else {
        setIsValidNote(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: null,
          sortColumn: "NEWLY_ADDED",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
        };
        setIsLoading(true);

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLIST}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          // setUserPlayList(fetchPage?data:prev=>[...prev,...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );

          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        setScrollLast(false);
      }
    }
  }
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);

        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.PLAYLISTDATAASSOCIATEDWITHVIDEO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);

          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
          };
          setIsLoading(true);

          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDVIDEOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;

    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
          let updatedSelectedPlaylist = [...prevSelectedPlaylist];

          if (checked) {
            if (
              !updatedSelectedPlaylist.some(
                (playlist) => playlist?.id === playlistId
              )
            ) {
              updatedSelectedPlaylist.push({ id: playlistId });
            }
          } else {
            updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
              (playlist) => playlist.id !== playlistId
            );
          }

          return updatedSelectedPlaylist;
        })
      : removeFromExisting(playlistId);
  };

  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };

  const fetchVideo = async (id) => {

    // STOP THE MUSIC PLAYER 
    setTrack([])
    setSingleTrack([])
    setPlayNextTrack([])

    setCurrentlyPlaylingVidId(id);
    window.scrollTo(0, 0);
    try {
      const Auth = await localStorage.getItem("userToken");
      if (Auth == "01") {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.PLAYVIDFORLOGINUSERS + id
        );
        if (data) {
          setItem(data);
          // setDocumentData([...data.audioContents, ...data.ebookContents, ...data.padContents, ...data.patraContents, ...data.transcriptContents])
          setDocumentDataAudio(data?.audioContents);
          setDocumentDataPad(data?.padContents);
          setDocumentDataPatra(data?.patraContents);
          setDocumentDataEbook(data?.ebookContents);
          setDocumentDataTranscript(data?.transcriptContents);
          fetchSuggestedList(data?.videoTitle);
          setTimeout(() => {
            setShowLoader(false);
            setShowSuggestions(true);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      } else {
        const { data } = await apiCall(
          "GET",
          ApiEndPoint.PLAYVIDFORNONLOGINUSERS + id
        );
        if (data) {
          setItem(data);
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setShowLoader(false);
          }, 2000);
        }
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const handlePause = async (playerRef) => {
    if (playerRef?.current) {
      const stopTime = playerRef.current.getCurrentTime();
      if (auth) {
        try {
          const params = {
            id: currentlyPlaylingVidId,
            lastWatchTime: stopTime,
          };

          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.SAVEWATCHVIDEOTIME}`,
            params
          );

          if (data.length > 0) {
            return true;
          } else {
            return true;
          }
        } catch (err) {
          console.error("API request failed:", err);
          return true; // Handle the error and return false
        }
      }
    }
    return true;
  };

  useLayoutEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault(); // Ensure the event doesn't navigate away immediately

      try {
        const result = await handlePause(playerRef);
        if (result) {
          // Allow navigation when the API call is successful
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.assign(`/videoPlayPage/${currentlyPlaylingVidId}`);
        } else {
          // Handle errors here, such as showing a warning to the user
          event.returnValue = "Are you sure you want to leave?";
        }
      } catch (err) {
        console.error("Error handling beforeunload:", err);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [item]);
  useLayoutEffect(() => {
    return async () => {
      await handlePause(playerRef);
    };
  }, [location]);

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>
                    {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                  </h3>
                  <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
                </div>
                {!auth ? (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          onChange({ true: true });
                          onClose();
                        }}
                        variant="contained"
                        className="signBtnHome"
                      >
                        <img
                          style={{ height: "23px", width: "23px" }}
                          className="g-icon"
                          src={Google}
                        ></img>
                        Sign-in
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="button__group">
                      <Button
                        onClick={() => {
                          navigate("/subscription");
                          onClose();
                        }}
                        variant="contained"
                        className="subscriptionBtn"
                        // className="signBtnHome"
                      >
                        {/* <img className="g-icon" src={Google}></img> */}
                        Subscribe
                      </Button>
                      <button className="no-btn" onClick={onClose}>
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          },
        })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }

  const addFavorite = async (item) => {
    if (auth) {
      // videoList[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setIsLoading(false);
          setItem({ ...item, myFavourite: item.myFavourite ? false : true });
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
        console.log("err: ", err);
      }
    } else {
      takeOtherSubcription();
    }
  };

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("767"));

  function getFileExtension(url) {
    const parts = url.split(".");
    return parts[parts.length - 1];
  }

  const handleDownloadClick = async (contentUrl, index, type) => {
    try {
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);
      setFileDownloadLoader(index);
      setFileDownloadLoaderType(type);
      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        setFileDownloadLoaderType(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      setFileDownloadLoaderType(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReadOrPlayClick = (contentUrl, contentType) => {
    const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
      contentUrl
    )}`;
    window.open(newTabUrl, "_blank");
  };

  const createPlaylist = () => {
    setIsOpen(false);
    // navigate("/createdPlaylist");
    setShowCreatePlayListModel(true);
  };

  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const videoWatchLater = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const suggestedAddFavorite = async (item, index) => {
    if (auth) {
      suggestedVideoList[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setRefresh(!refresh);
        } else {
          setRefresh(!refresh);
        }
      } catch (err) {
        setRefresh(!refresh);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  const selectPlayList = () => {};

  function MinutesToHoursMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours + ":" + remainingMinutes;
  }

  // Use the seekTo method to start the video at 10 seconds
  // useEffect(() => {
  //   if (playerRef.current) {
  //     // Set the video's playback position to the saved position
  //     playerRef.current.seekTo(60);
  //   }
  // }, []);

  // const inputRef = useRef(null);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [playlistName]);

  const allArraysExist =
    documentDataAudio &&
    documentDataPad &&
    documentDataPatra &&
    documentDataEbook &&
    documentDataTranscript;

  const allArraysEmpty =
    allArraysExist &&
    !documentDataAudio.length &&
    !documentDataPad.length &&
    !documentDataPatra.length &&
    !documentDataEbook.length &&
    !documentDataTranscript.length;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleClickSuggestions = async (id) => {
    const isTimeSaved = await handlePause(playerRef);
    if (isTimeSaved) {
      fetchVideo(id);
    }
  };
  const handleGoBack = async () => {
    const scrollY = window.scrollY;
    const isTimeSaved = await handlePause(playerRef);
    if (isTimeSaved) {
      window.history.scrollRestoration = "manual";
      //if the docemnt title is not getting reset to 'SATSHRUT' 
      //navigate('/');
      
      navigate(-1);
      // Restore the scroll position to the top of the page.
      window.scrollTo(0, scrollY);
    }
  };
  function openLinkInNewTab(url) {
    window.open(url, "_blank");
  }

  function takeOtherSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  const handleFastForward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10, "seconds");
    }
  };

  const getTimeStamp = () => {
    let stamp;
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      stamp = formatTimeForTimeStamp(currentTime);
      //
      return stamp;
    }
  };



  const handleTitleClick = async (item) => {
    if (!item?.audioUrl) return;
    setTimeout(() => {
      handlePauseVideo()
    }, 1000);
    const trackObject = {
      id: item?.id,
      title: item?.videoTitle,
      url: item?.audioUrl,
      thumbnailUrl: item?.thumbnails,
      lyrics: null,
      artist: item?.speaker,
    };


    setTimeout(() => {
          const stopTime = playerRef.current.getCurrentTime();
          setStopTime(stopTime);
          setSingleTrack([]);
          setTrack([]);
          setPlayNextTrack([]);
          setPlayingShuffledAlbumId(null);
          setPlayingAlbumId(null);
          let trackList = [];
          trackList[0] = trackObject;
          setTrack(trackList);
    }, 1000);
  
    if (isMusicPlayerVisible) {
      setIsMusicPlayerVisible(false);
    }
  
    setTimeout(() => {
      setIsMusicPlayerVisible(true);
    }, 900);
  
  
    // navigate('/')
    setTimeout(() => {
      setStopTime(null)
    }, 1100);
  };
  


  // const getTimeStamp = () => {
  //   let stamp;
  //   if (playerRef.current) {
  //     const currentTime = playerRef.current.getCurrentTime();
  //     stamp = formatTimeForTimeStamp(currentTime);
  //     //
  //     return stamp;
  //   }
  // };

  const handleRewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime - 10, "seconds");
    }
  };

  return (
    <section className="videoPlayPageOuterMain video-play-top">
      {/*----- Individual Video Title -----*/}
      <div className="video-play-title-mt-20">
        <span className="video-play-title-d-flex" 
        onClick={handleGoBack}
        >
          <img
            className="video-play-c-pointer"
            src={AllImages.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
          ></img>
          <p className="video-play-heading-title">{"Individual Video"}</p>
        </span>
      </div>
      {showLoader && (
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <div
            className="video-div1"
            style={{
              width: "100%",
              boxSizing: "borderBox",
              height: matchesMD === true ? 550 : 300,
              position: "absolute",
              boxSizing: "border-box",
              right: "0",
              left: "0",
            }}
          >
            <div
              className="video-div2"
              style={{
                backgroundColor: "#000",
                width: "100%",
                boxSizing: "border-box",
                height: matchesMD === true ? 550 : 300,
                textAlign: "center",
                display: "flex",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={loader} alt="loading..." />
            </div>
          </div>
        </div>
      )}
      {item && item.videoUrl != null ? (
         <div className="video-play-page-video-player-container">
          {/*----- Video Player -----*/}
          <ReactPlayer
            url={item.videoUrl}
            ref={playerRef}
            controls={true}
            playing={isPlaying}
            pip={true}
            stopOnUnmount={false}
            onPlay={handlePlayingVideo}
            onPause={handlePausingVideo}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  fs: 1,
                  modestbranding: 1,
                  rel: 0,
                  showRelatedVideos: false,
                  start: item.lastWatchTime,
                },
              },
            }}
            width="100%"
            height={matchesMD === true ? 550 : 300}
          />
          <div className="video-play-page-video-play-controls">
            <span className="video-play-control-d-flex" onClick={handleRewind}>
              <FaBackward className="video-play-page-video-play-backward-icon" />
            </span>

            <span
              className="video-play-control-d-flex"
              onClick={handleFastForward}
            >
              <FaForward className="video-play-page-video-play-forward-icon" />
            </span>
          </div>
          {/*----- Mark Favorite and Unfavorite -----*/}
        </div>
      ) : (
        item && (
          <div
            className="video-play-container"
            style={{ backgroundColor: "black" }}
            onClick={() => takeSubcription(item)}
          >
            {/*----- Thumbnail and Lock -----*/}
            <div className="video-play-thumbnail-div lokedDiv">
              <img
                className={`video-play-thumbnail ${
                  getImageNameFromResolution(item.thumbnails) !=
                    "maxresdefault" && "image-resolution"
                }`}
                src={item.thumbnails}
              />
              <div className="video-play-layer-image"></div>
              <div className="video-play-lock-div">
                <div className="video-play-like-count badgeShow">
                  <i
                    className="fa fa-lock"
                    onClick={() => takeSubcription(item)}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <div className="video-play-title-div">
        {item && (
          <div className="videoPlayPageOuter">
            <div className="videoPlayPage">
              {/*----- Video Title -----*/}
              <p className="video-title">{item?.videoTitle}</p>

              {/*----- Time and Month -----*/}
              <div className="video-play-d-flex video-play-mt-5">
                <div className="video-play-d-flex">
                  <span className="video-play-hrs-time">
                    {" "}
                    {MinutesToHoursMinutes(item?.videoDuration)}{" "}
                    {item?.videoDuration ? "hrs" : null}
                  </span>
                  {/* <span className="video-play-hrs-txt">hrs</span> */}
                </div>
                {/* <div className="video-play-dot"></div> */}
                {/* <div className="video-play-d-flex">
                  <span className="video-play-hrs-time">{item?.numberOfLike}</span>
                  <span className="video-play-hrs-txt"> ago</span>
                </div> */}
              </div>
            </div>
            {/*----- Share Icon -----*/}
            <div className="share-options">
              <div className="video-play-d-flex video-play-mt-5">
                <div className="favTooltip">
                  <img
                    className="video-play-mr-10"
                    onClick={() => addFavorite(item)}
                    src={
                      item?.myFavourite ? AllImages.Favorite_sel : AllImages.Fav
                    }
                    alt="Favourite"
                    title="Favourite"
                  ></img>
                  <span className="tooltiptext">
                    {!item.myFavourite ? "Mark Favorite" : "Unfavorite"}
                  </span>
                </div>
                {/* <RWebShare
                  data={{
                    // text: "Like humans, flamingos make friends for life",
                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                    title: "Video Share",
                  }}
                  onClick={() => console.log("share")}
                >
                  <div className="copyTooltip">
                    <img
                      className="video-play-mr-10"
                      src={AllImages?.Copy}
                      alt="Copy"
                      title="Copy"
                    ></img>
                    <span className="tooltiptext">{"Copy"}</span>
                  </div>
                </RWebShare> */}
                {/* {video?.crowed && video?.incidentComments && (
                  <div className="readTooltip">
                    <button
                      onClick={() => openSaveInfoModal()}
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        width: "fit-content",
                      }}
                      className="readTooltip"
                    >
                      <img
                        style={{
                          padding: "3px",
                          width: "18px",
                          height: "18px",
                        }}
                        className="video-play-mr-10"
                        src={AllImages?.CreateNote}
                        alt="Create Note"
                        title="Create Note"
                      />
                      <span className="tooltiptext">{"Create a note"}</span>
                    </button>
                  </div>
                )} */}
                <div className="readTooltip">
                  <img
                    className="video-play-mr-10"
                    src={AllImages?.ControlPoint}
                    onClick={() => openModal()}
                  ></img>
                  <span className="tooltiptext">{"Add to My Playlists"}</span>
                </div>
                <RWebShare
                  data={{
                    url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                    title: "Video Share",
                  }}
                  onClick={() => console.log("share")}
                >
                  <div className="readTooltip">
                    <img
                      className="video-play-mr-10"
                      src={AllImages?.ShareOutline}
                      alt="Share"
                      title="Share"
                    ></img>
                    <span className="tooltiptext">{"Share Video"}</span>
                  </div>
                </RWebShare>

                {/* <div className="readTooltip">
                  <img
                    className="video-play-mr-10"
                    src={item?.audioUrl ? AllImages?.AudioAvailable : AllImages?.AudioNotAvailable}
                    onClick={() => handleTitleClick(item)}
                  ></img>
                    <span className="tooltiptext">{item.audioUrl ? 'Audio Available' : 'Audio Not Available'}</span>                
                </div> */}
              </div>
            </div>
          </div>
        )}
      </div>

      {/*----- Satsang Audio -----*/}
      {/* {item?.audioUrl && (
        <div className="audio-container">  
          <label 
            htmlFor={`audio-${item?.id}`} 
            className="audio-label"
          >
            Satsang Audio
          </label>
          <audio 
            ref={audioRef}
            controls 
            controlsList="nodownload noplaybackrate"
            src={item?.audioUrl} 
            id={`audio-${item?.id}`} 
            className="audio-player"
          />
        </div>
      )} */}


      {/*----- Reference Material -----*/}
      {showSuggestions && (
        <div className="mobile-ref-material">
          <p className="video-play-reference-material">
            {"Reference Material"}
          </p>
          {!allArraysEmpty ? (
            <div className="document_card">
              {documentDataAudio?.map((content, index) => (
                <div className="video-play-reference-material-card">
                  <p className="reference-material-card-title">
                    Audio
                    <span className="reference-material-card-type">
                      (Audio)
                    </span>
                  </p>
                  <p className="reference-material-card-title">
                    {content.title}
                    
                  </p>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {/* {fileDownloadLoader == index &&
                  fileDownloadLoaderType == "Audio" ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 20, width: 20 }}
                    />
                  ) : (
                    <div className="readTooltip">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadClick(
                            content.contentUrl,
                            index,
                            "Audio"
                          )
                        }
                        src={AllImages?.YellowDownload}
                        alt="Download"
                        title="Download"
                      />
                      <span className="tooltiptext">{"Download"}</span>
                    </div>
                  )} */}
                    {/* <span
                    style={{ cursor: "pointer" }}
                    className="reference-material-download-type"
                    onClick={() =>
                      handleReadOrPlayClick(
                        content.contentUrl,
                        getFileExtension(content.contentUrl)
                      )
                    }
                  >
                    PLAY
                  </span> */}
                    <div style={{ display: "flex", gap: "5px" }}>
                      {content?.contentUrlApple && (
                        <img
                          src={AllImages?.appleMusicSvg}
                          className="musicIcons read-play-type-btn"
                          onClick={() =>
                            openLinkInNewTab(content?.contentUrlApple)
                          }
                          alt="Apple Music"
                          title="Apple Music"
                        />
                      )}
                      {content?.contentUrlSpotify && (
                        <img
                          src={AllImages?.spotifyMusicSvg}
                          className="musicIcons read-play-type-btn"
                          onClick={() =>
                            openLinkInNewTab(content.contentUrlSpotify)
                          }
                          alt="Spotify"
                          title="Spotify"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {documentDataPad?.map((content, index) => (
                <div className="video-play-reference-material-card">
                  <p className="reference-material-card-title">
                    Pad
                    <span className="reference-material-card-type">
                      (Audio)
                    </span>
                  </p>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {/* {fileDownloadLoader == index &&
                  fileDownloadLoaderType == "PAD" ? (
                    <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 20, width: 20 }}
                    />
                  ) : (
                    <div className="readTooltip">
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDownloadClick(content.contentUrl, index, "PAD")
                        }
                        src={AllImages?.YellowDownload}
                        alt="Download"
                        title="Download"
                      ></img>
                      <span className="tooltiptext">{"Download"}</span>
                    </div>
                  )}
                  <span
                    style={{ cursor: "pointer" }}
                    className="reference-material-download-type"
                    onClick={() => handleReadOrPlayClick(content.contentUrl)}
                  >
                    {" "}
                    PLAY
                  </span> */}
                    <div style={{ display: "flex", gap: "5px" }}>
                      {content?.contentUrlApple && (
                        <img
                          src={AllImages?.appleMusicSvg}
                          className="musicIcons read-play-type-btn"
                          onClick={() =>
                            openLinkInNewTab(content.contentUrlApple)
                          }
                          alt="Apple Music"
                          title="Apple Music"
                        />
                      )}
                      {content?.contentUrlSpotify && (
                        <img
                          src={AllImages?.spotifyMusicSvg}
                          className="musicIcons read-play-type-btn"
                          onClick={() =>
                            openLinkInNewTab(content.contentUrlSpotify)
                          }
                          alt="Spotify"
                          title="Spotify"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}

              {documentDataPatra?.map((content, index) => (
                <div className="video-play-reference-material-card">
                  <p className="reference-material-card-title">
                    Patra
                    <span className="reference-material-card-type">{`(${capitalizeFirstLetter(
                      getFileExtension(content.contentUrl)
                    )})`}</span>
                  </p>
                  <p className="reference-material-card-title">
                    {content.title}
                    
                  </p>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {fileDownloadLoader == index &&
                    fileDownloadLoaderType == "Patra" ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      <div className="readTooltip">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadClick(
                              content.contentUrl,
                              index,
                              "Patra"
                            )
                          }
                          src={AllImages?.YellowDownload}
                          alt="Download"
                          title="Download"
                        ></img>
                        <span className="tooltiptext">{"Download"}</span>
                      </div>
                    )}
                    <span
                      style={{ cursor: "pointer" }}
                      className="reference-material-download-type"
                      onClick={() => handleReadOrPlayClick(content.contentUrl)}
                    >
                      {" "}
                      Read
                    </span>
                  </div>
                </div>
              ))}

              {documentDataEbook?.map((content, index) => (
                <div className="video-play-reference-material-card">
                  <p className="reference-material-card-title">
                    Ebook
                    <span className="reference-material-card-type">{`(${capitalizeFirstLetter(
                      getFileExtension(content.contentUrl)
                    )})`}</span>
                  </p>
                  <p className="reference-material-card-title">
                    {content.title}
                    
                  </p>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {fileDownloadLoader == index &&
                    fileDownloadLoaderType == "Ebook" ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      <div className="readTooltip">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadClick(
                              content.contentUrl,
                              index,
                              "Ebook"
                            )
                          }
                          src={AllImages?.YellowDownload}
                          alt="Download"
                          title="Download"
                        ></img>
                        <span className="tooltiptext">{"Download"}</span>
                      </div>
                    )}
                    <span
                      style={{ cursor: "pointer" }}
                      className="reference-material-download-type"
                      onClick={() => handleReadOrPlayClick(content.contentUrl)}
                    >
                      {" "}
                      READ
                    </span>
                  </div>
                </div>
              ))}

              {documentDataTranscript?.map((content, index) => (
                <div className="video-play-reference-material-card">
                  <p className="reference-material-card-title">
                    Transcript
                    <span className="reference-material-card-type">{`(${capitalizeFirstLetter(
                      getFileExtension(content.contentUrl)
                    )})`}</span>
                  </p>
                  <p className="reference-material-card-title">
                    {content.title}
                    
                  </p>
                  <div className="video-play-d-flex video-play-jc-sb video-play-mt-30">
                    {fileDownloadLoader == index &&
                    fileDownloadLoaderType == "Transcript" ? (
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 20, width: 20 }}
                      />
                    ) : (
                      <div className="readTooltip">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleDownloadClick(
                              content.contentUrl,
                              index,
                              "Transcript"
                            )
                          }
                          src={AllImages?.YellowDownload}
                          alt="Download"
                          title="Download"
                        ></img>
                        <span className="tooltiptext">{"Download"}</span>
                      </div>
                    )}
                    <span
                      style={{ cursor: "pointer" }}
                      className="reference-material-download-type"
                      onClick={() => handleReadOrPlayClick(content.contentUrl)}
                    >
                      {" "}
                      READ
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataAvailable />
          )}
        </div>
      )}

      {/*----- Suggested Video -----*/}
      {showSuggestions && (
        <div className="mobile-ref-material suggestedVideo">
          <p className="video-play-suggested-video">{"Suggested Video"}</p>
          <div className="suggested-video-column-count-3 custom-row">
            {suggestedVideoList?.map((item, index) => (
              <div className="custom-col-4 custom-col">
                <div className="suggested-video-play-card-wrap">
                  <div
                    className="suggested-video-play-inside-wrap"
                    style={{ position: "relative" }}
                  >
                    {item.videoUrl ? (
                      <div
                        className="suggested-video-play-list-image"
                        onClick={() => handleClickSuggestions(item.id)}
                      >
                        <img
                          className={`suggested-video-img-banner ${
                            getImageNameFromResolution(item?.thumbnails) !=
                              "maxresdefault" && "image-resolution"
                          }`}
                          src={item.thumbnails}
                          // key={item.id}
                          alt="Card Image"
                        />
                        <div className="playIconTooltip">
                          <img
                            className="suggested-video-play-play-icon"
                            src={AllImages.PlayIcon}
                            alt="play Icon"
                            title="Play Icon"
                          />
                          <span className="tooltiptext">{"Play Video"}</span>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="ind-card-play-list-image"
                        style={{ position: "static" }}
                      >
                        <div
                        // onClick={() => takeSubcription(item)}
                        >
                          <img
                            className={`ind-card-img-banner ${
                              getImageNameFromResolution(item?.thumbnails) !=
                                "maxresdefault" && "image-resolution"
                            }`}
                            src={
                              item?.thumbnails
                                ? `${item?.thumbnails}`
                                : `${AllImages.DefaultThumbnail}`
                            }
                            alt="Card Image"
                            title="card Image"
                          />
                          <div className="layerImage"></div>
                        </div>
                        <div className="star1 badgestarInvisible">
                          <div className="LikeCount badgeShow">
                            <i
                              className="fa fa-lock"
                              // onClick={() => takeSubcription(item)}
                            ></i>
                          </div>
                          <span className="tooltiptext">
                            {"Subscribe for more Videos"}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="suggested-video-play-card-content-item">
                      <div className="suggested-video-play-video-content">
                        <div>
                          <p className="suggested-video-play-title-text f-poppins">
                            {item.videoTitle}
                          </p>
                          <p className="suggested-video-play-text-hrs f-poppins">
                            {MinutesToHoursMinutes(item.videoDuration) }{" "}
                            {item.videoDuration ? "hrs" : null}
                          </p>
                        </div>
                        <div className="suggested-video-mt-10">
                          <div className="timerTooltip">
                            <img
                              onClick={() =>
                                item?.watchLater
                                  ? RemoveWatchLaterVideo(item, index)
                                  : videoWatchLater(item, index)
                              }
                              src={
                                item?.watchLater
                                  ? AllImages.SelectedTimer
                                  : AllImages.Timer
                              }
                              alt="Timer"
                              title="Timer"
                            ></img>
                            <span className="tooltiptext">
                              {!item.watchLater
                                ? "Watch Later"
                                : "Remove Watch Later"}
                            </span>
                          </div>
                          <div className="favTooltip">
                            <img
                              src={
                                item?.myFavourite
                                  ? AllImages.Favorite_sel
                                  : AllImages.Fav
                              }
                              alt="Favourite"
                              title="Favourite"
                              onClick={() => suggestedAddFavorite(item, index)}
                            ></img>
                            <span className="tooltiptext">
                              {!item.myFavourite
                                ? "Mark Favorite"
                                : "Unfavorite"}
                            </span>
                          </div>
                          <RWebShare
                            data={{
                              url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                              title: "Video Share",
                            }}
                            onClick={() => console.log("share")}
                          >
                            <div className="copyTooltip">
                              <img
                                src={AllImages.Copy}
                                alt="Copy"
                                title="Copy"
                              ></img>
                              <span className="tooltiptext">{"Copy"}</span>
                            </div>
                          </RWebShare>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/*----- Add to My Playlists Modal -----*/}
      {isOpen && (
        <Dialog
          open={openModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={"paper"}
        >
          <DialogContent
            // ref={dialogContentRef}
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: "320px", // Adjust the maximum height as needed
              margin: 5,
            }}
            // onScroll={handlePlaylistScroll}
          >
            <div className="add-my-playlist-modal-content">
              <div className="add-my-playlist-modal-d-flex modal-mb-5">
                <p className="add-my-playlist-modal-msg-text">
                  {"Add to My Playlists"}
                </p>
                <span
                  className="add-my-playlist-modal-close-button"
                  onClick={closeModal}
                >
                  &times;
                </span>
              </div>
              <div>
                {userPlayList &&
                  userPlayList.map((item, index) => (
                    <div
                      key={item.id}
                      className="add-my-playlist-modal-d-flex modal-mt-15"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox_${item.id}`}
                        name={`checkbox_${item.id}`}
                        data-playlist-id={item.id}
                        checked={isPlaylistSelected(item.id)}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="add-my-playlist-checkbox-text"
                        htmlFor={`checkbox_${item.id}`}
                      >
                        {item.playListName}
                      </label>
                    </div>
                  ))}
                {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                  <p
                    onClick={fetchMoreUserPlaylistData}
                    className="fetch-more-playlist"
                  >
                    MORE+
                  </p>
                )}
              </div>
              <div className="modal-mt-15" onClick={createPlaylist}>
                <label className="add-my-playlist-create-playlist">
                  Create Playlist
                </label>
              </div>
            </div>
            {isLoading && (
              <div className="loader">
                <img src={loader} alt="loading..." />
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="add-my-playlist-modal-action">
              <button
                onClick={closeModal}
                className="add-my-playlist-modal-cancel-btn"
              >
                {"Cancel"}
              </button>
              <button
                className="add-my-playlist-modal-add-btn"
                onClick={addVideoAgainstPlaylist}
              >
                {"confirm"}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {showSaveInfo && (
        <Dialog
          open={openSaveInfoModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal save-video-info"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Note"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeSaveInfoModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Video Title"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={video.videoTitle}
                  disabled={true}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Video Link"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input  `}
                  type="text"
                  value={window.location.href}
                  disabled={true}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Start Time"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"End Time"}
                </p>
                <input
                  className={`f-poppins my-create-playlist-modal-search-input `}
                  type="text"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>
              <div className="language">
                <p className="my-create-playlist-modal-playlist-name">
                  {"Note Title"}
                </p>
                <select
                  value={noteTitle}
                  onChange={(e) => setNoteTitle(e.target.value)}
                >
                  <option value="Shabdarth" key="0">
                    Shabdarth
                  </option>
                  <option value="Purusharth" key="1">
                    Purusharth
                  </option>
                  <option value="Shanka" key="2">
                    Shanka
                  </option>
                </select>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Note"}
                </p>
                <textarea 
                  placeholder="Note"
                  className={`f-poppins my-create-playlist-modal-search-input ${
                    isValidNote ? "invalid" : null
                  } `}
                  style={{height: "100px",lineHeight:"20px",wordSpacing:0}}
                  type="textarea"
                  cols={10}
                  
                  value={note}
                  onChange={handleNoteChange}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeSaveInfoModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmitNote()}
              >
                {createNoteBtnLding ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
      {/* {openRecaptcha &&
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent style={{
              overflowY: "hidden",
              overflowX: "hidden",
            }}>

              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block", }}
              />
            </DialogContent>
          </Dialog>
        } */}

        
      {showCreatePlayListModel && (
        <Dialog
          open={showCreatePlayListModel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="createPlaylistModal"
        >
          <DialogContent>
            <div className="my-create-playlist-modal-content">
              <div className="modal-d-flex">
                <p className="my-created-playlist-modal-msg-text">
                  {"Create a Playlist"}
                </p>
                <span
                  className="my-created-playlist-modal-close-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  &times;
                </span>
              </div>
              <div>
                <p className="my-create-playlist-modal-playlist-name">
                  {"Playlist Name"}
                </p>
                <input
                  placeholder="Spiritual Playlist"
                  className={`f-poppins my-create-playlist-modal-search-input ${
                    isValidPlaylistName ? "invalid" : null
                  } `}
                  type="text"
                  id="playlistName"
                  value={playlistName}
                  onChange={handlePlaylistNameChange}
                  // ref={inputRef} 
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="my-created-playlist-modal-action-content">
              <button
                className="my-create-playlist-cancel-btn"
                onClick={() => closeCreatedPlayListModal()}
              >
                {"Cancel"}
              </button>
              <button
                className="my-create-playlist-create-btn"
                onClick={() => handleSubmit()}
              >
                {createPlaylistBtnLding ? (
                  <img src={loader} style={{ width: 20, height: 20 }} />
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </section>
  );
}
export default VideoPlayPage;
