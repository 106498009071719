import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import {
  MusicDataContext,
  MusicPlayerVisibilityContext,
  MusicTracksContext,
  ShowCreatePlayListContext,
  userDetailsContext,
  UserloggedContext
} from "components/Context/UserContext";
import { useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import apiEndPoints from "utils/apiEndPoints";
import { apiCall } from "utils/httpClient";
import Google from "../../../assets/images/google.png";
import loader from "../../../assets/images/loading_wbg.gif";
import {
  AllImages,
  BhaktiPadPlaylistShareUrl,
  emojiRegex,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
} from "../../../utils/constants";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import "./style.css";
//playlist
import { toast } from "react-toastify";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { RWebShare } from "react-web-share";
function AlbumList() {
  const navigate = useNavigate();
  const [auth, setAuth] = useContext(UserloggedContext);
  const [musicData, setMusicData] = useContext(MusicDataContext);
  const { track, setTrack } = useContext(MusicTracksContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );
  const [searchText, setSearchText] = useState(""); // State for search text
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [pageNo, setPageNo] = useState(0); // State for page number
  const [scrollLast, setScrollLast] = useState(true);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { openRecaptcha, recaptchaFun, onChange } = useGoogleLoginFlow();
  const SortingOptions = {
    NEWLY_ADDED: "NEWLY_ADDED",
    PREVIOUSLY_ADDED: "PREVIOUSLY_ADDED",
    POPULAR_BHAKTI_PADS: "POPULAR_VIDEOS",
    Sort_A_To_Z: "A_TO_Z",
    Sort_Z_To_A: "Z_TO_A",
  };
  //playlist
  const [isOpen, setIsOpen] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(
    ShowCreatePlayListContext
  );
  const [userPlayList, setUserPlayList] = useState([]);
  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [selectedFilterOp, setSelectedFilterOp] = useState(
    SortingOptions.Sort_A_To_Z
  );
  const [userDetails, setUserDetails] = useContext(userDetailsContext);

  //playlist
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
    fetchData()
  }, [isOpen, selectedFilterOp]);
  const openModal = (id) => {
    if (auth) {
      setIsOpen(true);
      setCurrentlyPlaylingVidId(id);
    } else {
      // takeOtherSubcription();
    }
  };


  async function handleAllTrack(fetchPage = 0) {
    setIsLoading(true);
    try {
      const body = {
        pageNo: fetchPage ? fetchPage - 1 : pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
        sortDirection: "asc",
        searchValue: searchText,
      };
      if (auth) {
        const res = await apiCall('POST', `${apiEndPoints.GETALLALBUMS}`, body);
        const data = res.data.content;
        if (data.length > 0) {
          setIsLoading(false);
          setMusicData(RemoveDuplicates(fetchPage ? data : musicData.concat(data)));
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(data.length === 20);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          fetchPage && setMusicData([]);
          setScrollLast(false);
          setScrollHashMap(false);
          setPageNo(0);
        }
      } else {
        takeSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error('Error fetching audio tracks:', error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: "",
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: "",
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : (prev) => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: "YOUTUBE_TRACK",
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const handleCheckboxChange = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun(1);
  };
  const createPlaylist = () => {
    setIsOpen(false);
    navigate("/CreatedPlaylistForAudio");
    setShowCreatePlayListModel(true);
  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };


  async function infiniteScroll() {
    setIsLoading(true);
    try {
      const body = {
        pageNo: pageNo,
        pageSize: 20,
        sortColumn: selectedFilterOp,
        sortDirection: "asc",
        searchValue: "",
      };
      if (auth) {
        const res = await apiCall("POST", `${apiEndPoints.GETALLALBUMS}`, body);
        const data = res.data.content;
        if (data.length > 0) {
          setIsLoading(false);
          setMusicData(musicData.concat(data)); // Update musicData correctly
          setPageNo(pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          !pageNo && setMusicData([]); // Clear musicData if fetching a new page
          setScrollLast(false);
          setScrollHashMap(false);
          setPageNo(0);
        }
      } else {
        takeSubscription();
      }
    } catch (error) {
      setIsLoading(false);
      setScrollLast(false);
      setScrollHashMap(false);
      console.error("Error fetching audio tracks:", error);
    }
    isOpenSortBy && closeModalSortBy();
  }
  const handleSearchInputChange = (e) => {
    if (!emojiRegex.test(e.target.value)) {
      if (e.target.value) {
        setSearchText(e.target.value);
      } else {
        setSearchText(e.target.value);
      }
    }
  };
  const fetchData = () => {
    handleAllTrack(1);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };
  // const handleTitleClick = (item) => {
  //   setTrack({
  //     trackId: item?.trackId,
  //     title: item?.title,
  //     subtitle: "",
  //     play: true,
  //   });
  //   setIsMusicPlayerVisible(true);
  // };
  const handleRadioChange = (event) => {
    setSelectedFilterOp(event.target.value);
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };
  function capitalizeFirstWord(str) {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  }
  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  // useEffect(() => {
  //   if (isOpen) {
  //     fetchUserPlaylistData();
  //   }
  // }, [isOpen]);



  // async function toggleFavoriteTrack(album, index) {
  //   try {
  //     const isCurrentlyFavorite = album.isFavourite;
  //     const method = isCurrentlyFavorite ? "DELETE" : "POST";
  //     const endpoint = isCurrentlyFavorite
  //       ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${album.id}`
  //       : `${apiEndPoints.TRACKSFAVORITE}/${album.id}`;
  //     musicData[index].isFavourite = !isCurrentlyFavorite;
  //     const data = await apiCall(method, endpoint);
  //     console.log(data)
  //     setRefresh(!refresh);
  //     setIsLoading(false);
  //     const message = isCurrentlyFavorite
  //       ? "Removed from favorites successfully!"
  //       : "Added to favorites successfully!";
  //     toast.success(message);
  //   } catch (err) {
  //     setRefresh(!refresh);
  //     setIsLoading(false);
  //     toast.error("Something went wrong, try again");
  //   }
  // }


  // async function toggleFavoriteTrack(album, index) {
  //   try {
  //     setIsLoading(true);
  //     const isCurrentlyFavorite = album.isFavourite;
  //     const method = isCurrentlyFavorite ? "DELETE" : "POST";
  //     const endpoint = isCurrentlyFavorite
  //       ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${album.id}`
  //       : `${apiEndPoints.TRACKSFAVORITE}/${album.id}`;

  //     const response = await apiCall(method, endpoint);
  //     if (response.success) {
  //       // Update the state only after a successful response
  //       setMusicData((prevData) => {
  //         const updatedData = [...prevData];
  //         updatedData[index] = {
  //           ...updatedData[index],
  //           isFavourite: !isCurrentlyFavorite
  //         };
  //         return updatedData;
  //       });

  //       const message = isCurrentlyFavorite
  //         ? "Removed from favorites successfully!"
  //         : "Added to favorites successfully!";
  //       toast.success(message);
  //     } else {
  //       throw new Error("Failed to update favorite status");
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     toast.error("Something went wrong, try again");
  //   } finally {
  //     setIsLoading(false);
  //     setRefresh(!refresh);
  //   }
  // }

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }

  const handleFavoriteTracks = async (item, type, action) => {
    try {
      setMusicData((prevMusicData) => {
        const updatedMusicData = [...prevMusicData];
        const albumIndex = updatedMusicData.findIndex((x) => x.id === item.id);
        if (albumIndex !== -1) {
          updatedMusicData[albumIndex].isFavourite = !updatedMusicData[albumIndex].isFavourite;
        }
        return updatedMusicData;
      });

      const apiUrl = `${action === 'add' ? apiEndPoints.ALBUMSFAVORITE : apiEndPoints.ALBUMSFAVORITEDELETE}/${item.id}`;
      const method = action === 'add' ? 'POST' : 'DELETE';

      const response = await apiCall(method, apiUrl);
      if (response) {
      }
    } catch (error) {
      console.error('Error updating favorite tracks:', error);
    }
  };


  return (
    <section class={`albumList-page${isMusicPlayerVisible ? " ytPlayerOpen" : ""}`}>
      {/* <section class="albumList-page ytPlayerOpen"> */}
      <div className="albumList-page-title-outer">
        <div className="albumList-page-title">
          <span className="my-favorites-d-flex" onClick={() => navigate('/sparsh')}>
            <img
              className="my-favorites-c-pointer"
              src={AllImages.YellowArrow}
              alt="Back Arrow"
              title="Back Arrow"
            ></img>
            <p className="my-favorites-title">List of Album</p>
          </span>
        </div>
        {/*----- Search Bar Start -----*/}
        <div>
          <div className="p-list-search-wrap">
            <div className="search-input">
              {/* onClick={fetchData}  */}
              <span className="search-icon">
                {isLoading ? (
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 15, width: 15 }}
                  />
                ) : (
                  <div className="searchTooltip">
                    <img src={AllImages.Search} alt="Search" title="Search" />
                    <span className="tooltiptext">{"Search"}</span>
                  </div>
                )}
              </span>
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Album"
                value={searchText}
                onChange={handleSearchInputChange}
                onKeyUp={handleKeyPress}
              />
            </div>
            <div className="searchIcon-outer searchTooltip">
              <img
                src={AllImages.SearchIcon}
                alt="Search"
                title="Search"
                className="searchIcon"
              />
              <span className="tooltiptext">{"Search"}</span>
            </div>
            <div
              className="filter-icon sortbyTooltip"
              onClick={() => openModalSortBy()}
            >
              <img src={AllImages.SearchToggle} alt="Search" title="Search" />
              <span className="tooltiptext">{"Sort By"}</span>
            </div>
          </div>
        </div>
        {/*----- Search Bar End -----*/}
      </div>
      <div class="mainTitleOuter">
      </div>
      <InfiniteScroll
        className="mt-15"
        dataLength={musicData.length}
        next={() => infiniteScroll()}
        hasMore={scrollHashMap}
        loader={
          scrollLast ? (
            isLoading && (
              <center>
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 25, width: 25, marginTop: 20 }}
                />
              </center>
            )
          ) : (
            <p className="subtitle fancy">
              <span>End of results</span>
            </p>
          )
        }
      >
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={"paper"}
          >
            <DialogContent
              // ref={dialogContentRef}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "320px", // Adjust the maximum height as needed
                margin: 5,
              }}
            // onScroll={handlePlaylistScroll}
            >
              <div className="add-my-playlist-modal-content">
                <div className="add-my-playlist-modal-d-flex modal-mb-5">
                  <p className="add-my-playlist-modal-msg-text">
                    {"Add to My Playlists"}
                  </p>
                  <span
                    className="add-my-playlist-modal-close-button"
                    onClick={closeModal}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  {userPlayList &&
                    userPlayList?.map((item, index) => (
                      <div
                        key={item.id}
                        className="add-my-playlist-modal-d-flex modal-mt-15"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox_${item.id}`}
                          name={`checkbox_${item.id}`}
                          data-playlist-id={item.id}
                          checked={isPlaylistSelected(item.id)}
                          onChange={handleCheckboxChange}
                        />
                        <label
                          className="add-my-playlist-checkbox-text"
                          htmlFor={`checkbox_${item.id}`}
                        >
                          {item.playListName}
                        </label>
                      </div>
                    ))}
                  {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                    <p
                      onClick={fetchMoreUserPlaylistData}
                      className="fetch-more-playlist"
                    >
                      MORE
                    </p>
                  )}
                </div>
                <div className="modal-mt-15" onClick={createPlaylist}>
                  <label className="add-my-playlist-create-playlist">
                    Create Playlist
                  </label>
                </div>
              </div>
              {isLoading && (
                <div className="loader">
                  <img src={loader} alt="loading..." />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <div className="add-my-playlist-modal-action">
                <button
                  onClick={closeModal}
                  className="add-my-playlist-modal-cancel-btn"
                >
                  {"Cancel"}
                </button>
                <button
                  className="add-my-playlist-modal-add-btn"
                  onClick={addVideoAgainstPlaylist}
                >
                  {"confirm"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        <div
          class={(musicData?.length > 0) ? "albumOuter" : "albumOuter noData"} >
          {musicData?.length > 0 ? musicData?.map((album, index) => (
            <div className="album"
              key={album?.id}>
              <div className={userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? "albumTop" : "albumTop albumLockOuter"}>
                <img src={album.thumbnailUrl ? album.thumbnailUrl : AllImages?.Album5} alt="img" class="albumImg" />
                <div className="albumHover">
                  <div className="albumHoverInner">
                    {/* <div className="favTooltip">
                      <img
                        src={AllImages?.Time}
                        className="albumInnerImg"
                        alt="Time Icon"
                      />
                    </div> */}

                    <div className="favTooltip">
                      <img
                        onClick={() => {
                          handleFavoriteTracks(album, 'albums', album.isFavourite ? 'delete' : 'add')
                        }}

                        src={
                          album.isFavourite
                            ? AllImages.Favorite_sel
                            : AllImages?.Heart
                        }
                        alt="IMG"
                      ></img>
                      <span className="tooltiptext">
                        {!album.isFavourite ? "Mark Favorite" : "Unfavorite"}
                      </span>
                    </div>

                    <div className="favTooltip">
                      <RWebShare
                        data={{
                          // text: "Like humans, flamingos make friends for life",
                          url: `${BhaktiPadPlaylistShareUrl}/playlist/${album.id}`,
                          title: "Playlist Share",
                        }}
                        onClick={() => console.log("share")}
                      >
                        <div className="copyTooltip">
                          <img
                            className="cursor"
                            src={AllImages.CopyPNG}
                            alt="Copy"
                            title="Copy"
                          ></img>
                          <span className="tooltiptext">
                            {"Copy"}
                          </span>
                        </div>
                      </RWebShare>
                    </div>
                  </div>
                  <div className="albumInnerPlayIconOuter"
                    onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? navigate(`/playlist/${album?.id}`) : takeSubscription() }}>
                    {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                      <div className="bhaktipadPlayIconTooltip">
                        <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" alt="img" />
                        <div className="bhaktipadPlayIconTooltipInner">
                          <span className="tooltiptext">
                            {"Play BhaktiPad Playlist"}
                          </span>
                        </div>
                      </div>
                      :
                      <div className="star1 badgestarInvisible albumLock">
                        <div className="LikeCount badgeShow">
                          <i
                            className="fa fa-lock"
                            onClick={() => takeSubscription(album)}
                          ></i>
                        </div>
                        <span className="tooltiptext">
                          {"Subscribe to use this feature"}
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="albumBottom">
                <p className="name">{album?.title}</p>
                <div className="desc">
                  <p class="time">{convertMinutesToHoursAndMinutes(album?.durationTotalMin) + ' hrs'}</p>
                  <p class="dot">&nbsp;</p>
                  <p class="count"> {album?.totalActiveTracks} bhakti pads</p>
                </div>
              </div>
            </div>
          ))
            :
            <div className="loader">
              {isLoading ? (
                <img
                  src={loader}
                  alt="loading..."
                  style={{ height: 35, width: 35, marginTop: 20 }}
                />
              ) : (
                <NoDataAvailable />
              )}
            </div>}
        </div>
      </InfiniteScroll>
      {
        isOpenSortBy && (
          <Dialog
            open={openModalSortBy}
            className="sortBy"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div className="qu-history-modal-msg-content">
                <p className="qu-history-modal-msg-text">{"Sort by"}</p>
                <span
                  className="qu-history-modal-close-button"
                  onClick={() => closeModalSortBy()}
                >
                  &times;
                </span>
              </div>
              <ul>
                {Object.keys(SortingOptions).map((key) => (
                  <li key={key}>
                    <label className="customRadio">
                      {SortingOptions[key] === "A_TO_Z" ||
                        SortingOptions[key] === "Z_TO_A"
                        ? key.replace(/_/g, " ")
                        : capitalizeFirstWord(
                          key.replace(/_/g, " ").toLowerCase()
                        )}
                      <input
                        type="radio"
                        value={SortingOptions[key]}
                        checked={selectedFilterOp === SortingOptions[key]}
                        onChange={handleRadioChange}
                        name="radio"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </Dialog>
        )
      }
    </section >
  );
}
export default AlbumList;
