import React, { useState, useContext, useEffect, useRef } from "react";
import { Box, TextField } from "@material-ui/core";
import "./style.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  AllImages,
  GLOBAL_URL,
  MinutesToHoursMinutes,
  DateTimeAgo,
  RemoveDuplicates,
  VideoPlaylistShareUrl,
  getImageNameFromResolution,
  googleClientId,
  isNumberValid,
  isStringValid,
  BhaktiPadPlaylistShareUrl,
  emojiRegex,
} from "../../../utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slider from "@mui/material/Slider";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme, useMediaQuery } from "@material-ui/core";
import { useNavigate, useLocation, useParams, Await } from "react-router-dom";
import {
  AdvanceSearchTabContext,
  LoaderContext,
  UserloggedContext,
  AdvanceSearchDataContext,
  AdvanceFilterValContext,
  AdvanceSearchTextContext,
  AdvanceNoDataFoundContext,
  SatsangAllLocationContext,
  SatsangAllSpeakersContext,
  MusicTracksContext,
  MusicTrackContext,
  MusicPlayerVisibilityContext,
  ShowCreatePlayListContext,
  userDetailsContext,
  PlayingAlbumIdContext,
  PlayingShuffledAlbumIdContext,
  PlayNextContext,
  CurrentTrackIndex,
} from "../../Context/UserContext";
import ApiEndPoint from "../../../utils/apiEndPoints";
import { setDefaultHeader, apiCall } from "../../../utils/httpClient";
import loader from "../../../assets/images/logo.png";
import NoDataAvailable from "../../common/NoDataFound/NoDataAvailable";
import InfiniteScroll from "react-infinite-scroll-component";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Button from "@material-ui/core/Button";
import Google from "../../../assets/images/google.png";
import {
  GoogleLogin,
  useGoogleLogout,
  useGoogleLogin,
} from "react-google-login";
import { gapi } from "gapi-script";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "../../Context/context";
import useGoogleLoginFlow from "components/common/useGoogleLoginFlow";
import DatePicker, { DatePickerRef } from "react-multi-date-picker";
import indian from "react-date-object/calendars/indian";

import indian_hi from "react-date-object/locales/indian_hi";
import Icon from "react-multi-date-picker/components/icon";
import { Table } from "reactstrap";
import apiEndPoints from "../../../utils/apiEndPoints";

import { useDispatch, useSelector } from 'react-redux';
import { addTrack } from "actions/Index";

const clientId = googleClientId;
const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
function AdvanceSearch() {
  const dispatch = useDispatch();
  const playlistTracks = useSelector(state => state.tempPlaylist.playlistTracks); // Use playlistTracks
  const navigate = useNavigate();
  const transformSearchKey = (rawSearchKey) => {
    if (rawSearchKey === "null") {
      return null;
    }
    return rawSearchKey;
  };
  const { searchKeys, contentType } = useParams();
  const location = useLocation();
  const searchKey = transformSearchKey(searchKeys);
  const pathParts = location?.pathname?.split("/");

  const startDuration = new URLSearchParams(location.search).get(
    "startDuration"
  );
  const endDuration = new URLSearchParams(location.search).get("endDuration");
  const uploadDuration = new URLSearchParams(location.search).get(
    "uploadDuration"
  );
  const videolocation = new URLSearchParams(location.search).get(
    "videolocation"
  );
  const startDate = new URLSearchParams(location.search).get("startDate");
  const endDate = new URLSearchParams(location.search).get("endDate");
  const languages = new URLSearchParams(location.search).get("language");
  const speaker = new URLSearchParams(location.search).get("speaker");
  const videotype = new URLSearchParams(location.search).get("videotype");
  const title = new URLSearchParams(location.search).get("title");
  const author = new URLSearchParams(location.search).get("author");
  const writtenTo = new URLSearchParams(location.search).get("writtenTo");
  const writtenBy = new URLSearchParams(location.search).get("writtenBy");
  const recommendedBy = new URLSearchParams(location.search).get(
    "recommendedBy"
  );
  const publisher = new URLSearchParams(location.search).get("publisher");
  const comments = new URLSearchParams(location.search).get("comments");
  const version = new URLSearchParams(location.search).get("version");
  const topic = new URLSearchParams(location.search).get("topic");
  const patraNoStart = new URLSearchParams(location.search).get("patraNoStart");
  const patraNoEnd = new URLSearchParams(location.search).get("patraNoEnd");
  const yearOfPatraStart = new URLSearchParams(location.search).get(
    "yearOfPatraStart"
  );
  const [selectedItemToShare, setSelectedItemToShare] = useState(null);
  const yearOfPatraEnd = new URLSearchParams(location.search).get(
    "yearOfPatraEnd"
  );
  const indianCalFrom = new URLSearchParams(location.search).get(
    "indianCalFrom"
  );
  const indianCalTo = new URLSearchParams(location.search).get("indianCalTo");
  const [refresh, setRefresh] = useState(false);

  const [satsangLocations, setSatsangLocations] = useContext(
    SatsangAllLocationContext
  );
  const { signInFun, signOutFun } = React.useContext(AuthContext);

  const [satsangSpeaker, setSatsangSpeaker] = useContext(
    SatsangAllSpeakersContext
  );
  const [advaceFilterVal, setAdvaceFilterVal] = useContext(
    AdvanceFilterValContext
  );
  const [IsLoading, setIsLoading] = useContext(LoaderContext);
  const [advaceTabValue, setAdvaceTabValue] = useContext(
    AdvanceSearchTabContext
  );
  const [advaceData, setAdvaceData] = useContext(AdvanceSearchDataContext);
  const [searchValue, setSearchValue] = useContext(AdvanceSearchTextContext);
  const [dataNoFound, setDataNotFound] = useContext(AdvanceNoDataFoundContext);
  const [auth, setAuth] = useContext(UserloggedContext);
  const [pageReload, setPageReload] = useState(false);
  const [tabActive, setTabActive] = useState(true);
  const { playingAlbumId, setPlayingAlbumId } = useContext(PlayingAlbumIdContext);
  const { playingShuffledAlbumId, setPlayingShuffledAlbumId } = useContext(PlayingShuffledAlbumIdContext);
  const {
    openRecaptcha,

    recaptchaFun,

    onChange,
  } = useGoogleLoginFlow();
  const [value, setValue] = React.useState([1, 500]);
  const [playlistValue, setPlaylistValue] = useState([0, 6]);
  const [ebookValue, setEbookValue] = useState([80, 240]);
  const [transcriptValue, setTranscriptValue] = useState([80, 240]);
  const [patraValue, setPatraValue] = useState([80, 240]);
  const [padValue, setPadValue] = useState([80, 240]);
  const [filterlocation, setFilterLocation] = useState(null);
  const [isOpenAdvance, setIsOpenAdvance] = useState(false);
  const [scrollHashMap, setScrollHashMap] = useState(false);
  const [scrollLast, setScrollLast] = useState(true);
  const [pageNo, setPageNo] = useState(0);

  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputTitle, setInputTitle] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [type, setType] = useState(null);
  const [filLanguage, setFilLanguage] = useState(null);
  const [isShareDialoguOpen, setIsShareDialoguOpen] = useState(false);
  const [filterSpeakers, setFilterSpeakers] = useState(null);

  const [googleLogin, setGoogleLogin] = useState(false);

  const [filterPlayListlocation, setFilterPlayListlocation] = useState(null);
  const [playListFromDate, setPlayListFromDate] = useState(null);
  const [padFromDate, setPadFromDate] = useState(null);
  const [transcriptFromDate, setTranscriptFromDate] = useState(null);
  const [transcriptToDate, setTranscriptToDate] = useState(null);
  const [patraToDate, setPatraToDate] = useState(null);
  const [patraFromDate, setPatraFromDate] = useState(null);

  const [toDate, setToDate] = useState(null);
  const [playListToDate, setPlayListToDate] = useState(null);
  const [padToDate, setPadToDate] = useState(null);

  const [ebookLanguage, setEbookLanguage] = useState(null);
  const [padLanguage, setPadLanguage] = useState(null);
  const [transcriptLanguage, setTranscriptLanguage] = useState(null);

  const [playListTitle, setPlayListTitle] = useState(null);
  const [padTitle, setPadTitle] = useState(null);
  const [padAuthor, setPadAuthor] = useState(null);
  const [ebookAuthor, setEbookAuthor] = useState(null);
  const [patraAuthor, setPatraAuthor] = useState(null);
  const [padWrittenTo, setPadWrittenTo] = useState(null);
  const [patraWrittenTo, setPatraWrittenTo] = useState(null);
  const [ebookPublisher, setEbookPublisher] = useState(null);
  const [ebookVersion, setEbookVersion] = useState(null);
  const [ebookComments, setEbookComments] = useState(null);
  const [transcriptTopic, setTranscriptTopic] = useState(null);

  const [filterPlaylistSpeakers, setFilterPlaylistSpeakers] = useState(null);
  const [transcriptSpeakers, setTranscriptSpeakers] = useState(null);

  const [transcriptPatraNoStart, setTranscriptPatraNoStart] = useState(null);
  const [patrapatraNoStart, setPatraPatraNoStart] = useState(null);
  const [transcriptPatraNoEnd, setTranscriptPatraNoEnd] = useState(null);
  const [patrapatraNoEnd, setPatraPatraNoEnd] = useState(null);
  const [yearPatraStart, setYearPatraStart] = useState(null);
  const [yearPatraEnd, setYearPatraEnd] = useState(null);

  const [indianCalndrFromDate, setIndianCalndrFromDate] = useState(null);
  const [indianCalndrToDate, setIndianCalndrToDate] = useState(null);
  const [indianCalndrFromDateObj, setIndianCalndrFromDateObj] = useState(null);
  const [indianCalndrToDateObj, setIndianCalndrToDateObj] = useState(null);
  const [currentTimeOut, setCurrentTimeOut] = useState(null);
  const [padIndianCalndrFromDate, setPadIndianCalndrFromDate] = useState(null);
  const [padIndianCalndrToDate, setPadIndianCalndrToDate] = useState(null);
  const [errorArray, setErrorArray] = useState([]);
  const [ebookSeriesWrittenBy, setEbookSeriesWrittenBy] = useState(null);
  const [ebookSeriesTitle, setEbookSeriesTitle] = useState(null);
  const [ebookSeriesAuthor, setEbookSeriesAuthor] = useState(null);
  const [ebookSeriesWrittenTo, setEbookSeriesWrittenTo] = useState(null);
  const [ebookSeriesFromDate, setEbookSeriesFromDate] = useState(null);
  const [ebookSeriesToDate, setEbookSeriesToDate] = useState(null);
  const [filterEbookSerieslocation, setFilterEbookSerieslocation] =
    useState(null);
  const [ebookSeriesRecommendedBy, setEbookSeriesRecommendedBy] =
    useState(null);

  const [isMobileView, setIsMobileView] = useState(false);


  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 991);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  //YOUTUBE_TRACK
  const [userDetails, setUserDetails] = useContext(userDetailsContext);
  const [bhaktiPadFromDate, setBhaktiPadFromDate] = useState(null);
  const [bhaktiPadToDate, setBhaktiPadToDate] = useState(null);
  const [bhaktiPadAuthor, setBhaktiPadAuthor] = useState(null);
  const [bhaktiPadTitle, setBhaktiPadTitle] = useState(null);
  const [bhaktiPadArtist, setBhaktiPadArtist] = useState(null);
  const [bhaktiPadDuration, setBhaktiPadDuration] = useState([
    startDuration ? startDuration : 1,
    endDuration ? endDuration : 500,]);
  const { track, setTrack } = useContext(MusicTracksContext);
  

  const { singleTrack, setSingleTrack } = useContext(MusicTrackContext);
  const [isMusicPlayerVisible, setIsMusicPlayerVisible] = useContext(
    MusicPlayerVisibilityContext
  );
  const [currentlyPlaylingVidId, setCurrentlyPlaylingVidId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(null);
  const [trackDetailsForShare, setTrackDetailsForShare] = useState(null);
  const { playNextTrack, setPlayNextTrack } = useContext(PlayNextContext);

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const { currentTrackIndex, setCurrentTrackIndex } = useContext(CurrentTrackIndex);

  const [unselectedPlaylistArray, setUnselectedPlaylistArray] = useState([]);
  const [
    currentlySelectedUserPlaylistArray,
    setCurrentlylySelectedUserPlaylistArray,
  ] = useState([]);
  const [
    previouslySelectedUserPlaylistArray,
    setPreviouslySelectedUserPlaylistArray,
  ] = useState([]);
  const [userPlayList, setUserPlayList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showCreatePlayListModel, setShowCreatePlayListModel] = useContext(ShowCreatePlayListContext);
  const [isValidPlaylistName, setIsValidPlaylistName] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [createPlaylistBtnLding, setPlaylistBtnLding] = useState(false);


  //YOUTUBE_ALBUM
  const [albumFromDate, setAlbumFromDate] = useState(null);
  const [albumToDate, setAlbumToDate] = useState(null);
  const [albumAuthor, setAlbumAuthor] = useState(null);
  const [albumTitle, setAlbumTitle] = useState(null);
  const [albumDuration, setAlbumDuration] = useState([
    startDuration ? startDuration : 1,
    endDuration ? endDuration : 500,]);

  const getFormatedDate = (date) => {
    if (date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = yyyy + "-" + mm + "-" + dd;
      return formattedToday;
    }
  };
  const hasError = (field) => {
    const id = errorArray.findIndex((error) => error.field == field);

    if (id || id === 0) {
      return errorArray[id];
    } else {
      return null;
    }
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setTimeout(() => {
        setPopupVisible(null);
      }, 500);
    }
  };

  useEffect(() => {
    if (isPopupVisible !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible]);


  function takeSubscription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please Subscribe to Paid Yearly (12 months) plan to play this Bhakti Pad`}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                      alt="img"
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                  // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }


  function validateFields(params) {
    const currentYear = new Date().getFullYear();
    const errorArray = [];

    // Validate the author field if it's not empty
    if (params.author && !isStringValid(params.author)) {
      errorArray.push({
        field: "author",
        message: "Author should contain at least one alphabet character",
      });
    }

    // if (params.title && !isStringValid(params.title)) {
    //   errorArray.push({
    //     field: "Title",
    //     message: "Title should contain at least one alphabet character",
    //   });
    // }

    // Validate the publisher field if it's not empty
    if (params.publisher && !isStringValid(params.publisher)) {
      errorArray.push({
        field: "publisher",
        message: "Publisher should contain at least one alphabet character",
      });
    }

    // Validate the comments field if it's not empty
    if (params.comments && !isStringValid(params.comments)) {
      errorArray.push({
        field: "comments",
        message: "Comments should contain at least one alphabet character",
      });
    }

    // Validate the speaker field if it's not empty
    if (params.speaker && !isStringValid(params.speaker)) {
      errorArray.push({
        field: "speaker",
        message: "Speaker should contain at least one alphabet character",
      });
    }

    // Validate the topic field if it's not empty
    if (params.topic && !isStringValid(params.topic)) {
      errorArray.push({
        field: "topic",
        message: "Topic should contain at least one alphabet character",
      });
    }

    // Validate the written to field if it's not empty
    if (params.writtenTo && !isStringValid(params.writtenTo)) {
      errorArray.push({
        field: "writtenTo",
        message: "Written To should contain at least one alphabet character",
      });
    }
    if (params.writtenBy && !isStringValid(params.writtenBy)) {
      errorArray.push({
        field: "writtenBy",
        message: "Written By should contain at least one alphabet character",
      });
    }
    if (params.recommendedBy && !isStringValid(params.recommendedBy)) {
      errorArray.push({
        field: "recommendedBy",
        message:
          "Recommended By should contain at least one alphabet character",
      });
    }

    if (params.patraNoStart && !isNumberValid(params.patraNoStart)) {
      errorArray.push({
        field: "patraNoStart",
        message: "Patra number  should contain numbers only",
      });
    }
    if (params.patraNoEnd && !isNumberValid(params.patraNoEnd)) {
      errorArray.push({
        field: "patraNoEnd",
        message: "Patra number  should contain numbers only",
      });
    }
    if (params.yearOfPatraEnd && !isNumberValid(params.yearOfPatraEnd)) {
      errorArray.push({
        field: "yearOfPatraEnd",
        message: "Patra year To should  contain numbers only",
      });
    }
    if (params.yearOfPatraStart && !isNumberValid(params.yearOfPatraStart)) {
      errorArray.push({
        field: "yearOfPatraStart",
        message: "Patra year To should  contain numbers only",
      });
    }
    if (
      !errorArray.some((error) => error.field === "Patra Year Start") &&
      params.yearOfPatraStart &&
      (!/^\d{4}$/.test(params.yearOfPatraStart) ||
        params.yearOfPatraStart > currentYear)
    ) {
      errorArray.push({
        field: "yearOfPatraStart",
        message: `Invalid Patra Year Start. It should be in "YYYY" format and less than or equal to ${currentYear}.`,
      });
    }

    if (
      !errorArray.some((error) => error.field === "Patra Year End") &&
      params.yearOfPatraEnd &&
      (!/^\d{4}$/.test(params.yearOfPatraEnd) ||
        params.yearOfPatraEnd > currentYear)
    ) {
      errorArray.push({
        field: "yearOfPatraEnd",
        message: `Invalid Patra Year End. It should be in "YYYY" format and less than or equal to ${currentYear}.`,
      });
    }
    if (
      !errorArray.some((error) => error.field === "Patra No End") &&
      params.patraNoStart &&
      params.patraNoEnd &&
      params.patraNoStart > params.patraNoEnd
    ) {
      errorArray.push({
        field: "patraNoStart",
        message: "Patra No End should not be less than Patra No Start",
      });
    }

    if (
      !errorArray.some((error) => error.field === "Patra Year End") &&
      params.yearOfPatraStart &&
      params.yearOfPatraEnd &&
      params.yearOfPatraStart > params.yearOfPatraEnd
    ) {
      errorArray.push({
        field: "patraNoEnd",
        message: "Patra Year End should not be less than Patra Year Start",
      });
    }

    if (errorArray.length === 0) {
      // No errors, all fields are valid or empty
      return true;
    } else {
      // Handle errors
      if (currentTimeOut) {
        clearTimeout(currentTimeOut);
        setCurrentTimeOut(null);
      }
      setErrorArray(errorArray);
      const timeOut = setTimeout(() => {
        setErrorArray([]);
      }, 3000);
      // errorArray.forEach((error) => {
      //   toast.error(`${error.field}: ${error.message}`);
      // });
      return false;
    }
  }

  const handleChangeFromDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          startDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setFromDate(date);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListFromDate(date);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadFromDate(date);
    } else if (contentType == "PATRA") {
      setPatraFromDate(date);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(date);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesFromDate(date);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadFromDate(date)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(date)
    }
  };

  const handleChangeToDate = (date) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          endDate: getFormatedDate(date),
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);

    if (contentType == "VIDEO") {
      setToDate(date);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListToDate(date);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadToDate(date);
    } else if (contentType == "PATRA") {
      setPatraToDate(date);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptToDate(date);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesToDate(date);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadToDate(date)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumToDate(date)
    }
  };

  const handleShareDialogue = (item) => {
    setSelectedItemToShare(item);
    setIsShareDialoguOpen((prev) => !prev);
  };

  const handleChangeLanguages = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          language: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setFilLanguage(event.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
      setEbookLanguage(event.target.value);
    } else if (contentType == "PAD") {
      setPadLanguage(event.target.value);
    } else if (contentType == "PATRA") {
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptLanguage(event.target.value);
    }
  };

  const handleChangeType = (event) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === "VIDEO") {
        return {
          ...item,
          videotype: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setType(event.target.value);
  };

  const handleTitle = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          title: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setInputTitle(e.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlayListTitle(e.target.value);
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadTitle(e.target.value);
    } else if (contentType == "PATRA") {
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(e.target.value);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(e.target.value)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumTitle(e.target.value)
    }
  };

  const handleWrittenBy = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          writtenBy: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesWrittenBy(e.target.value);
    }
  };

  const handleRecommendedBy = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          recommendedBy: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesRecommendedBy(e.target.value);
    }
  };
  const handleSpeakers = (event) => {
    const updatedSpeakerValue = event.target.value;

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        if (contentType === "YOUTUBE_TRACK") {
          return {
            ...item,
            speaker: updatedSpeakerValue,
            author: updatedSpeakerValue,
          };
        } else {
          return {
            ...item,
            speaker: updatedSpeakerValue,
          };
        }
      }
      return item;
    });

    setAdvaceFilterVal(updateArray);
    if (contentType === "VIDEO") {
      setFilterSpeakers(updatedSpeakerValue);
    } else if (contentType === "SYSTEM_PLAYLIST") {
      setFilterPlaylistSpeakers(updatedSpeakerValue);
    } else if (contentType === "EBOOK") {
    } else if (contentType === "PAD") {
    } else if (contentType === "PATRA") {
    } else if (contentType === "TRANSCRIPT") {
      setTranscriptSpeakers(updatedSpeakerValue);
    } else if (contentType === "YOUTUBE_TRACK") {
      setBhaktiPadArtist(updatedSpeakerValue);
    }
  };

  const handleAuthor = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          author: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
      setEbookAuthor(e.target.value);
    } else if (contentType == "PAD") {
      setPadAuthor(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraAuthor(e.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesAuthor(e.target.value);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadAuthor(e.target.value)
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumAuthor(e.target.value);
    }
  };

  const handleWrittenTo = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          writtenTo: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
    } else if (contentType == "SYSTEM_PLAYLIST") {
    } else if (contentType == "EBOOK") {
    } else if (contentType == "PAD") {
      setPadWrittenTo(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraWrittenTo(e.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesWrittenTo(e.target.value);
    }
  };

  const handlePublisher = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          publisher: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setEbookPublisher(e.target.value);
  };

  const handleVersion = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          version: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setEbookVersion(e.target.value);
  };

  const handleComments = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          comments: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setEbookComments(e.target.value);
  };

  const handleTopics = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          topic: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    setTranscriptTopic(e.target.value);
  };

  const handlePatraNoStart = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          patraNoStart: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "TRANSCRIPT") {
      setTranscriptPatraNoStart(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraPatraNoStart(e.target.value);
    }
  };

  const handlePatraNoEnd = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          patraNoEnd: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "TRANSCRIPT") {
      setTranscriptPatraNoEnd(e.target.value);
    } else if (contentType == "PATRA") {
      setPatraPatraNoEnd(e.target.value);
    }
  };

  const handleYearPatraStart = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          yearOfPatraStart: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "PATRA") {
      setYearPatraStart(e.target.value);
    }
  };

  const handleYearPatraEnd = (e) => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          yearOfPatraEnd: e.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "PATRA") {
      setYearPatraEnd(e.target.value);
    }
  };

  // const inputRef = useRef(null);

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [playlistName]);

  useEffect(() => {
    setAdvaceTabValue(contentType);
    const params = {
      contentType: contentType,
      pageNo: 0,
      pageSize: 15,
      searchValue: searchKey === "null" ? null : searchKey,
      startDuration: startDuration ? parseInt(startDuration) : null,
      endDuration: endDuration ? parseInt(endDuration) : null,
      location: videolocation,
      uploadDuration: uploadDuration,
      startDate: startDate,
      endDate: endDate,
      language: languages,
      speaker: speaker,
      videotype: videotype,
      title: title,
      author: author,
      writtenTo: writtenTo,
      writtenBy: writtenBy,
      recommendedBy: recommendedBy,
      publisher: publisher,
      version: version,
      comments: comments,
      topic: topic,
      patraNoStart: patraNoStart,
      patraNoEnd: patraNoEnd,
      yearOfPatraStart: yearOfPatraStart,
      yearOfPatraEnd: yearOfPatraEnd,
      indianStartdate: indianCalFrom,
      indianEndDate: indianCalTo,
    };
    setSearchValue(searchKey);
    setPageNo(0);
    advanceSearchList(params);
  }, [
    contentType,
    searchKey,
    startDuration,
    endDuration,
    startDate,
    videotype,
    endDate,
    languages,
    speaker,
    videolocation,
    uploadDuration,
    title,
    author,
    writtenTo,
    writtenBy,
    recommendedBy,
    publisher,
    version,
    comments,
    topic,
    patraNoEnd,
    patraNoStart,
    yearOfPatraStart,
    yearOfPatraEnd,
    indianCalFrom,
    indianCalTo,
  ]);

  useEffect(() => {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === contentType) {
        return {
          ...item,
          searchValue: searchKey,
          startDuration: startDuration ? parseInt(startDuration) : null,
          endDuration: endDuration ? parseInt(endDuration) : null,
          uploadDuration: uploadDuration,
          location: videolocation,
          startDate: startDate,
          endDate: endDate,
          language: languages,
          speaker: speaker,
          videotype: videotype,
          title: title,
          author: author,
          writtenTo: writtenTo,
          writtenBy: writtenBy,
          recommendedBy: recommendedBy,
          publisher: publisher,
          version: version,
          comments: comments,
          topic: topic,
          patraNoStart: patraNoStart,
          patraNoEnd: patraNoEnd,
          yearOfPatraStart: yearOfPatraStart,
          yearOfPatraEnd: yearOfPatraEnd,
          indianStartdate: indianCalFrom,
          indianEndDate: indianCalTo,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
    if (contentType == "VIDEO") {
      setValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
      setFilterLocation(videolocation);
      setFromDate(startDate);
      setToDate(endDate);
      setFilLanguage(languages);
      setFilterSpeakers(speaker);
      setType(videotype);
      setInputTitle(title);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setPlaylistValue([
        startDuration ? startDuration : 0,
        endDuration ? endDuration : 6,
      ]);
      setFilterPlayListlocation(videolocation ? videolocation : "");
      setFilterPlaylistSpeakers(speaker);
      setPlayListToDate(endDate);
      setPlayListFromDate(startDate);
      setPlayListTitle(title);
    } else if (contentType == "EBOOK") {
      setEbookValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setEbookAuthor(author ? author : null);
      setEbookLanguage(languages ? languages : "");
      setEbookPublisher(publisher ? publisher : null);
      setEbookVersion(version ? version : null);
      setEbookComments(comments ? comments : null);
    } else if (contentType == "PAD") {
      setPadValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 180,
      ]);
      setPadTitle(title ? title : null);
      setPadFromDate(startDate);
      setPadToDate(endDate);
      setPadLanguage(languages);
      setPadAuthor(author ? author : null);
      setPadWrittenTo(writtenTo ? writtenTo : null);
      setPadIndianCalndrFromDate(indianCalFrom ? indianCalFrom : null);
      setPadIndianCalndrToDate(indianCalTo ? indianCalTo : null);
    } else if (contentType == "PATRA") {
      setPatraValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
      setPatraFromDate(startDate ? startDate : "");
      setPatraToDate(endDate ? endDate : "");
      setPatraAuthor(author ? author : null);
      setPatraWrittenTo(writtenTo ? writtenTo : null);
      setPatraPatraNoStart(patraNoStart);
      setPatraPatraNoEnd(patraNoEnd);
      setYearPatraStart(yearOfPatraStart);
      setYearPatraEnd(yearOfPatraEnd);
      setIndianCalndrFromDate(indianCalFrom ? indianCalFrom : null);
      setIndianCalndrToDate(indianCalTo ? indianCalTo : null);
    } else if (contentType == "TRANSCRIPT") {
      setTranscriptFromDate(startDate);
      setTranscriptToDate(endDate);
      setTranscriptLanguage(languages);
      setTranscriptSpeakers(speaker);
      setTranscriptTopic(topic);
      setTranscriptPatraNoStart(patraNoStart);
      setTranscriptPatraNoEnd(patraNoEnd);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setEbookSeriesTitle(title ? title : null);
      setEbookSeriesAuthor(author ? author : null);
      setEbookSeriesWrittenTo(writtenTo ? writtenTo : null);
      setEbookSeriesWrittenBy(writtenBy ? writtenBy : null);
      setEbookSeriesFromDate(startDate ? startDate : "");
      setEbookSeriesToDate(endDate ? endDate : "");
      setFilterEbookSerieslocation(videolocation ? videolocation : "");
      setEbookSeriesRecommendedBy(recommendedBy ? recommendedBy : null);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadTitle(title ? title : null);
      setBhaktiPadAuthor(author ? author : null);
      setBhaktiPadFromDate(startDate)
      setBhaktiPadToDate(endDate);
      setBhaktiPadDuration([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumFromDate(startDate);
      setAlbumToDate(endDate);
      setAlbumAuthor(author ? author : null);
      setAlbumTitle(title ? title : null)
      setAlbumDuration([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
    }
  }, [
    videotype,
    languages,
    endDate,
    startDate,
    speaker,
    videolocation,
    title,
    topic,
    patraNoStart,
    patraNoEnd,
    yearOfPatraEnd,
    yearOfPatraStart,
    writtenTo,
    writtenBy,
    recommendedBy,
    author,
    comments,
    version,
    publisher,
    indianCalFrom,
    indianCalTo,
  ]);

  useEffect(() => {
    return () => setSearchValue("");
  }, []);

  async function advanceSearchList(params) {
    try {
      const { data } = await apiCall(
        "POST",
        `${auth ? ApiEndPoint.ADVANCESEARCH : ApiEndPoint.NONLOGINADVANCESEARCH
        }`,
        params
      );
      if (data.length > 0) {
        setAdvaceTabValue(contentType);
        setScrollHashMap(true);
        setIsLoading(false);
        setDataNotFound(false);
        setTabActive(true);
        setPageReload(!pageReload);
        setAdvaceData(RemoveDuplicates(data));
        data.length < 8 && setScrollHashMap(false);
        setScrollLast(true);
      } else {
        setTabActive(true);
        setPageReload(!pageReload);
        setAdvaceTabValue(contentType);
        setIsLoading(false);
        setDataNotFound(true);
        setAdvaceData([]);
        setScrollHashMap(false);
        setScrollLast(false);
      }
    } catch (err) {
      setAdvaceTabValue(contentType);
      setTabActive(true);
      setPageReload(!pageReload);
      setScrollHashMap(false);
      setDataNotFound(true);
      setIsLoading(false);
      setAdvaceData([]);
      setScrollLast(false);
    }
  }

  async function EbookSearchPoc() {
    try {
      let url;
      switch (contentType) {
        case "EBOOK":
          url = `${auth ? ApiEndPoint.EPUBSEARCH : ApiEndPoint.NONEPUBSEARCH
            }/${inputValue.trim()}`;
          break;
        case "PATRA":
          url = `${auth ? ApiEndPoint.PATRASEARCH : ApiEndPoint.NONPATRASEARCH
            }/${inputValue.trim()}`;
          break;
        case "TRANSCRIPT":
          url = `${auth
            ? ApiEndPoint.TRANSCRIPTSEARCH
            : ApiEndPoint.NONTRANSCRIPTSEARCH
            }/${inputValue.trim()}`;
          break;
      }
      const { data } = await apiCall("GET", url);
      if (data.length > 0) {
        setAdvaceData(data);
        closeAdvanceModal();
        setIsLoading(false);
      } else {
        setAdvaceData([]);
        closeAdvanceModal();
        setIsLoading(false);
      }
    } catch (err) {
      closeAdvanceModal();
      setAdvaceData([]);
      setIsLoading(false);
    }
  }

  async function ClearFilter(params) {
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === pathParts[2]) {
        return {
          ...item,
          uploadDuration: uploadDuration,
          searchValue: searchKey,
          startDuration: startDuration ? parseInt(startDuration) : null,
          endDuration: endDuration ? parseInt(endDuration) : null,
          location: videolocation,
          startDate: startDate,
          endDate: endDate,
          language: languages,
          speaker: speaker,
          videotype: videotype,
          title: title,
          author: author,
          writtenTo: writtenTo,
          writtenBy: writtenBy,
          recommendedBy: recommendedBy,
          publisher: publisher,
          version: version,
          comments: comments,
          topic: topic,
          patraNoStart: patraNoStart,
          patraNoEnd: patraNoEnd,
          yearOfPatraStart: yearOfPatraStart,
          yearOfPatraEnd: yearOfPatraEnd,
          indianStartdate: indianCalFrom,
          indianEndDate: indianCalTo,
        };
      }
      return item;
    });
    if (pathParts[2] == "VIDEO") {
      setValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
    } else if (pathParts[2] == "SYSTEM_PLAYLIST") {
      setPlaylistValue([
        startDuration ? startDuration : 0,
        endDuration ? endDuration : 6,
      ]);
    } else if (pathParts[2] == "EBOOK") {
      setEbookValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
    } else if (pathParts[2] == "PAD") {
      setPadValue([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 180,
      ]);
    } else if (pathParts[2] == "PATRA") {
      setPatraValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
    } else if (pathParts[2] == "TRANSCRIPT") {
      setTranscriptValue([
        startDuration ? startDuration : 80,
        endDuration ? endDuration : 240,
      ]);
    } else if (contentType == "YOUTUBE_TRACK") {
      setBhaktiPadDuration([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
    } else if (contentType == "YOUTUBE_ALBUM") {
      setAlbumDuration([
        startDuration ? startDuration : 1,
        endDuration ? endDuration : 500,
      ]);
    }

    setAdvaceFilterVal(updateArray);
    setIsOpenAdvance(false);
    const param = {
      contentType: pathParts[2],
      pageNo: 0,
      pageSize: 10,
      searchValue: pathParts[3],
      startDuration: null,
      endDuration: null,
      uploadDuration: null,
      location: null,
      startDate: null,
      endDate: null,
      language: null,
      speaker: null,
      videotype: null,
      title: null,
      author: null,
      writtenTo: null,
      writtenBy: null,
      recommendedBy: null,
      topic: null,
      patraNoStart: null,
      patraNoEnd: null,
      yearOfPatraStart: null,
      yearOfPatraEnd: null,
      indianStartdate: null,
      indianEndDate: null,
    };
    navigate(`/advanceSearch/${advaceTabValue}/${searchValue}`);
    advanceSearchList(param);
    emptyAllValus();
  }

  const emptyAllValus = () => {
    setValue([1, 500]);
    setFromDate(null);
    setToDate(null);
    setFilLanguage("");
    setFilterSpeakers("");
    setType("");
    setFilterLocation("");
  };

  const advacePageFilterFun = () => {
    if (searchValue != pathParts[3]) {
      const params = advaceFilterVal.filter(
        (item) => item.contentType == contentType
      )[0];
      params.searchValue = searchValue;
      closeAdvanceModal();
      advanceSearchList(params);
      // navigate(`/advanceSearch/${contentType}/${searchValue.trim()}?${params.startDuration ? `startDuration=${params.startDuration}` : ''}${params.endDuration ? `&endDuration=${params.endDuration}` : ''} ${params.location ? `&videolocation=${params.location}` : ''} ${params.uploadDuration ? `&uploadDuration=${params.uploadDuration}` : ''}`);
      const navigateUrl =
        `/advanceSearch/${encodeURIComponent(contentType)}/${encodeURIComponent(
          searchValue?.trim() === "" ? null : searchValue
        )}?` +
        `${params.startDuration
          ? `startDuration=${encodeURIComponent(params.startDuration)}`
          : ""
        }` +
        `${params.endDuration
          ? `&endDuration=${encodeURIComponent(params.endDuration)}`
          : ""
        }` +
        `${params.location
          ? `&videolocation=${encodeURIComponent(params.location)}`
          : ""
        }` +
        `${params.startDate
          ? `&startDate=${encodeURIComponent(params.startDate)}`
          : ""
        }` +
        `${params.videotype
          ? `&videotype=${encodeURIComponent(params.videotype)}`
          : ""
        }` +
        `${params.author ? `&author=${encodeURIComponent(params.author)}` : ""
        }` +
        `${params.writtenTo
          ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
          : ""
        }` +
        `${params.writtenBy
          ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
          : ""
        }` +
        `${params.recommendedBy
          ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
          : ""
        }` +
        `${params.publisher
          ? `&publisher=${encodeURIComponent(params.publisher)}`
          : ""
        }` +
        `${params.version ? `&version=${encodeURIComponent(params.version)}` : ""
        }` +
        `${params.topic ? `&topic=${encodeURIComponent(params.topic)}` : ""}` +
        `${params.patraNoStart
          ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
          : ""
        }` +
        `${params.patraNoEnd
          ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
          : ""
        }` +
        `${params.yearOfPatraStart
          ? `&yearOfPatraStart=${encodeURIComponent(params.yearOfPatraStart)}`
          : ""
        }` +
        `${params.yearOfPatraEnd
          ? `&yearOfPatraEnd=${encodeURIComponent(params.yearOfPatraEnd)}`
          : ""
        }` +
        `${params.indianStartdate
          ? `&indianCalFrom=${encodeURIComponent(params.indianStartdate)}`
          : ""
        }` +
        `${params.indianEndDate
          ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
          : ""
        }` +
        `${params.comments
          ? `&comments=${encodeURIComponent(params.comments)}`
          : ""
        }` +
        `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""}` +
        `${params.endDate ? `&endDate=${encodeURIComponent(params.endDate)}` : ""
        }` +
        `${params.language
          ? `&language=${encodeURIComponent(params.language)}`
          : ""
        }` +
        `${params.speaker ? `&speaker=${encodeURIComponent(params.speaker)}` : ""
        }` +
        `${params.uploadDuration
          ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
          : ""
        }`;

      navigate(navigateUrl);
    }
  };

  const handleTabChange = (event, newValue) => {
    setAdvaceData([]);
    setPageNo(0);
    setTabActive(false);

    const params = advaceFilterVal.filter(
      (item) => item.contentType == newValue
    )[0];
    params.searchValue = searchKey;

    const navigateUrl =
      `/advanceSearch/${encodeURIComponent(newValue)}/${encodeURIComponent(
        searchKey
      )}?` +
      `${params.startDuration
        ? `startDuration=${encodeURIComponent(params.startDuration)}`
        : ""
      }` +
      `${params.endDuration
        ? `&endDuration=${encodeURIComponent(params.endDuration)}`
        : ""
      }` +
      `${params.location
        ? `&videolocation=${encodeURIComponent(params.location)}`
        : ""
      }` +
      `${params.startDate
        ? `&startDate=${encodeURIComponent(params.startDate)}`
        : ""
      }` +
      `${params.videotype
        ? `&videotype=${encodeURIComponent(params.videotype)}`
        : ""
      }` +
      `${params.author ? `&author=${encodeURIComponent(params.author)}` : ""}` +
      `${params.writtenTo
        ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
        : ""
      }` +
      `${params.writtenBy
        ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
        : ""
      }` +
      `${params.recommendedBy
        ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
        : ""
      }` +
      `${params.publisher
        ? `&publisher=${encodeURIComponent(params.publisher)}`
        : ""
      }` +
      `${params.version ? `&version=${encodeURIComponent(params.version)}` : ""
      }` +
      `${params.topic ? `&topic=${encodeURIComponent(params.topic)}` : ""}` +
      `${params.patraNoStart
        ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
        : ""
      }` +
      `${params.patraNoEnd
        ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
        : ""
      }` +
      `${params.yearOfPatraStart
        ? `&yearOfPatraStart=${encodeURIComponent(params.yearOfPatraStart)}`
        : ""
      }` +
      `${params.yearOfPatraEnd
        ? `&yearOfPatraEnd=${encodeURIComponent(params.yearOfPatraEnd)}`
        : ""
      }` +
      `${params.indianStartdate
        ? `&indianCalFrom=${encodeURIComponent(params.indianStartdate)}`
        : ""
      }` +
      `${params.indianEndDate
        ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
        : ""
      }` +
      `${params.comments
        ? `&comments=${encodeURIComponent(params.comments)}`
        : ""
      }` +
      `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""}` +
      `${params.endDate ? `&endDate=${encodeURIComponent(params.endDate)}` : ""
      }` +
      `${params.language
        ? `&language=${encodeURIComponent(params.language)}`
        : ""
      }` +
      `${params.speaker ? `&speaker=${encodeURIComponent(params.speaker)}` : ""
      }` +
      `${params.uploadDuration
        ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
        : ""
      }`;

    navigate(navigateUrl);
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#FFB200",
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
    },

    "&.MuiTab-root": {
      fontSize: 15,
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 600,
      textTransform: "uppercase",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 5,
      alignItems: "flex-start",
      marginRight: 15,
      justifyContent: "end",
      minWidth: "auto",
    },
  });

  async function advanceSearch(newValue, type) {
    try {
      const params = advaceFilterVal.filter(
        (item) => item.contentType == newValue
      )[0];
      if (validateFields(params)) {
        params.searchValue = searchKey;
        // params.searchValue = null
        params.pageNo = type == 2 ? 0 : pageNo + 1;
        const { data } = await apiCall(
          "POST",
          `${auth ? ApiEndPoint.ADVANCESEARCH : ApiEndPoint.NONLOGINADVANCESEARCH
          }`,
          params
        );
        if (data.length > 0) {
          setScrollHashMap(true);
          setPageNo(pageNo + 1);
          setIsLoading(false);
          setDataNotFound(false);
          setTabActive(true);
          setIsOpenAdvance(false);
          setAdvaceData(
            RemoveDuplicates(type == 2 ? data : advaceData.concat(data))
          );
          data.length < 8 && setScrollHashMap(false);
          // navigate(`/advanceSearch/${advaceTabValue}/${searchValue}?${params.startDuration ? `startDuration=${params.startDuration}` : ''}${params.endDuration ? `&endDuration=${params.endDuration}` : ''} ${params.location ? `&videolocation=${params.location}` : ''}${params.uploadDuration ? `&uploadDuration=${params.uploadDuration}` : ''}`);

          const navigateUrl =
            `/advanceSearch/${encodeURIComponent(
              advaceTabValue
            )}/${encodeURIComponent(searchValue)}?` +
            `${params.startDuration
              ? `startDuration=${encodeURIComponent(params.startDuration)}`
              : ""
            }` +
            `${params.endDuration
              ? `&endDuration=${encodeURIComponent(params.endDuration)}`
              : ""
            }` +
            `${params.location
              ? `&videolocation=${encodeURIComponent(params.location)}`
              : ""
            }` +
            `${params.startDate
              ? `&startDate=${encodeURIComponent(params.startDate)}`
              : ""
            }` +
            `${params.videotype
              ? `&videotype=${encodeURIComponent(params.videotype)}`
              : ""
            }` +
            `${params.author
              ? `&author=${encodeURIComponent(params.author)}`
              : ""
            }` +
            `${params.writtenTo
              ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
              : ""
            }` +
            `${params.writtenBy
              ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
              : ""
            }` +
            `${params.recommendedBy
              ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
              : ""
            }` +
            `${params.publisher
              ? `&publisher=${encodeURIComponent(params.publisher)}`
              : ""
            }` +
            `${params.version
              ? `&version=${encodeURIComponent(params.version)}`
              : ""
            }` +
            `${params.topic ? `&topic=${encodeURIComponent(params.topic)}` : ""
            }` +
            `${params.patraNoStart
              ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
              : ""
            }` +
            `${params.patraNoEnd
              ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
              : ""
            }` +
            `${params.yearOfPatraStart
              ? `&yearOfPatraStart=${encodeURIComponent(
                params.yearOfPatraStart
              )}`
              : ""
            }` +
            `${params.yearOfPatraEnd
              ? `&yearOfPatraEnd=${encodeURIComponent(params.yearOfPatraEnd)}`
              : ""
            }` +
            `${params.indianStartdate
              ? `&indianCalFrom=${encodeURIComponent(params.indianStartdate)}`
              : ""
            }` +
            `${params.indianEndDate
              ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
              : ""
            }` +
            `${params.comments
              ? `&comments=${encodeURIComponent(params.comments)}`
              : ""
            }` +
            `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""
            }` +
            `${params.endDate
              ? `&endDate=${encodeURIComponent(params.endDate)}`
              : ""
            }` +
            `${params.language
              ? `&language=${encodeURIComponent(params.language)}`
              : ""
            }` +
            `${params.speaker
              ? `&speaker=${encodeURIComponent(params.speaker)}`
              : ""
            }` +
            `${params.uploadDuration
              ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
              : ""
            }`;

          navigate(navigateUrl);
          setScrollLast(true);
        } else {
          setIsOpenAdvance(false);
          setTabActive(true);
          setAdvaceTabValue(newValue);
          setIsLoading(false);
          setDataNotFound(true);
          setScrollHashMap(false);
          setScrollLast(false);
          // navigate(`/advanceSearch/${advaceTabValue}/${searchValue}?${params.startDuration ? `startDuration=${params.startDuration}` : ''}${params.endDuration ? `&endDuration=${params.endDuration}` : ''} ${params.location ? `&videolocation=${params.location}` : ''}${params.uploadDuration ? `&uploadDuration=${params.uploadDuration}` : ''}`);
          const navigateUrl =
            `/advanceSearch/${encodeURIComponent(
              advaceTabValue
            )}/${encodeURIComponent(searchValue)}?` +
            `${params.startDuration
              ? `startDuration=${encodeURIComponent(params.startDuration)}`
              : ""
            }` +
            `${params.endDuration
              ? `&endDuration=${encodeURIComponent(params.endDuration)}`
              : ""
            }` +
            `${params.location
              ? `&videolocation=${encodeURIComponent(params.location)}`
              : ""
            }` +
            `${params.startDate
              ? `&startDate=${encodeURIComponent(params.startDate)}`
              : ""
            }` +
            `${params.videotype
              ? `&videotype=${encodeURIComponent(params.videotype)}`
              : ""
            }` +
            `${params.author
              ? `&author=${encodeURIComponent(params.author)}`
              : ""
            }` +
            `${params.writtenTo
              ? `&writtenTo=${encodeURIComponent(params.writtenTo)}`
              : ""
            }` +
            `${params.writtenBy
              ? `&writtenBy=${encodeURIComponent(params.writtenBy)}`
              : ""
            }` +
            `${params.recommendedBy
              ? `&recommendedBy=${encodeURIComponent(params.recommendedBy)}`
              : ""
            }` +
            `${params.publisher
              ? `&publisher=${encodeURIComponent(params.publisher)}`
              : ""
            }` +
            `${params.version
              ? `&version=${encodeURIComponent(params.version)}`
              : ""
            }` +
            `${params.topic ? `&topic=${encodeURIComponent(params.topic)}` : ""
            }` +
            `${params.patraNoStart
              ? `&patraNoStart=${encodeURIComponent(params.patraNoStart)}`
              : ""
            }` +
            `${params.patraNoEnd
              ? `&patraNoEnd=${encodeURIComponent(params.patraNoEnd)}`
              : ""
            }` +
            `${params.comments
              ? `&comments=${encodeURIComponent(params.comments)}`
              : ""
            }` +
            `${params.yearOfPatraStart
              ? `&yearOfPatraStart=${encodeURIComponent(
                params.yearOfPatraStart
              )}`
              : ""
            }` +
            `${params.yearOfPatraEnd
              ? `&yearOfPatraEnd=${encodeURIComponent(params.yearOfPatraEnd)}`
              : ""
            }` +
            `${params.indianStartdate
              ? `&indianCalFrom=${encodeURIComponent(params.indianStartdate)}`
              : ""
            }` +
            `${params.indianEndDate
              ? `&indianCalTo=${encodeURIComponent(params.indianEndDate)}`
              : ""
            }` +
            `${params.title ? `&title=${encodeURIComponent(params.title)}` : ""
            }` +
            `${params.endDate
              ? `&endDate=${encodeURIComponent(params.endDate)}`
              : ""
            }` +
            `${params.language
              ? `&language=${encodeURIComponent(params.language)}`
              : ""
            }` +
            `${params.speaker
              ? `&speaker=${encodeURIComponent(params.speaker)}`
              : ""
            }` +
            `${params.uploadDuration
              ? `&uploadDuration=${encodeURIComponent(params.uploadDuration)}`
              : ""
            }`;

          navigate(navigateUrl);
          if (type == 2) {
            setAdvaceData([]);
          }
        }
      }
    } catch (err) {
      console.log("err: ", err);
      setIsOpenAdvance(false);
      setTabActive(true);
      setScrollHashMap(false);
      setDataNotFound(true);
      setIsLoading(false);
      setAdvaceData([]);
      setScrollLast(false);
    }
  }

  async function advanceSearchInfiniteScroll(newValue, type) {
    try {
      const params = advaceFilterVal.filter(
        (item) => item.contentType == newValue
      )[0];
      params.searchValue = searchKey;
      // params.searchValue = null
      params.pageNo = pageNo + 1;
      const { data } = await apiCall(
        "POST",
        `${auth ? ApiEndPoint.ADVANCESEARCH : ApiEndPoint.NONLOGINADVANCESEARCH
        }`,
        params
      );
      if (data.length > 0) {
        setScrollHashMap(true);
        setPageNo(pageNo + 1);
        setIsLoading(false);
        setDataNotFound(false);
        setTabActive(true);
        setIsOpenAdvance(false);
        setAdvaceData(RemoveDuplicates(advaceData.concat(data)));
        data.length < 8 && setScrollHashMap(false);
        setScrollLast(true);
      } else {
        setIsOpenAdvance(false);
        setTabActive(true);
        setAdvaceTabValue(newValue);
        setIsLoading(false);
        setDataNotFound(true);
        setScrollHashMap(false);
        setScrollLast(false);
        if (type == 2) {
          setAdvaceData([]);
        }
      }
    } catch (err) {
      setIsOpenAdvance(false);
      setTabActive(true);
      setScrollHashMap(false);
      setDataNotFound(true);
      setIsLoading(false);
      setAdvaceData([]);
      setScrollLast(false);
    }
  }

  //advance search
  const handleLocationChange = (event) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          location: event.target.value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);

    if (contentType == "VIDEO") {
      setFilterLocation(event.target.value);
    } else if (contentType == "SYSTEM_PLAYLIST") {
      setFilterPlayListlocation(event.target.value);
    } else if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      setFilterEbookSerieslocation(event.target.value);
    } else if (contentType == "PAD") {
    } else if (contentType == "PATRA") {
    }
  };

  const openAdvanceModal = () => {
    // if (searchValue?.length > 0) {
    setIsOpenAdvance(true);
    // }
  };
  const closeAdvanceModal = () => {
    setIsOpenAdvance(false);
  };

  const uploadDurationArray = [
    {
      value: "TODAY",
      lable: "TODAY",
    },
    {
      value: "THIS_WEEK",
      lable: "This week",
    },
    {
      value: "THIS_MONTH",
      lable: "This month",
    },
    {
      value: "THIS_YEAR",
      lable: "This year",
    },
    {
      value: "THREE_YEARS_AGO",
      lable: "2-3 year ago",
    },
  ];

  const onChangeSlider = (e, value) => {
    const [min, max] = value;
    if (advaceTabValue == "VIDEO") {
      setValue(value);
    } else if (advaceTabValue == "EBOOK") {
      setEbookValue(value);
    } else if (advaceTabValue == "PATRA") {
      setPatraValue(value);
    } else if (advaceTabValue == "SYSTEM_PLAYLIST") {
      setPlaylistValue(value);
    } else if (advaceTabValue == "PAD") {
      setPadValue(value);
    } else if (advaceTabValue == "TRANSCRIPT") {
      setTranscriptValue(value);
    } else if (advaceTabValue == "YOUTUBE_TRACK") {
      setBhaktiPadDuration(value)
    } else if (advaceTabValue == "YOUTUBE_ALBUM") {
      setAlbumDuration(value)
    }

    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          pageNo: 0,
          pageSize: 8,
          searchValue: searchValue,
          startDuration: min,
          endDuration: max,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };

  const uploadDurationselect = (value) => {
    const params = advaceFilterVal.filter(
      (item) => item.contentType == advaceTabValue
    )[0];
    const updateArray = advaceFilterVal.map((item) => {
      if (item.contentType === advaceTabValue) {
        return {
          ...item,
          uploadDuration: value == params.uploadDuration ? null : value,
        };
      }
      return item;
    });
    setAdvaceFilterVal(updateArray);
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("480"));

  // Video watch later and favourites
  const videoWatchLater = async (item, index) => {
    if (auth) {
      advaceData[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.VIDEOWATCHLATER}/${item.id}`
        );
        if (data) {
          setPageReload(!pageReload);
        } else {
          setPageReload(!pageReload);
        }
      } catch (err) {
        setPageReload(!pageReload);
        console.log("err: ", err);
      }
    } else {
    }
  };
  // Video watch later and favourites
  const RemoveWatchLaterVideo = async (item, index) => {
    if (auth) {
      advaceData[index].watchLater = item.watchLater ? false : true;
      try {
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.WATCHLATEDREMOVE}/${item.id}`
        );
        if (data) {
          setPageReload(!pageReload);
        } else {
          setPageReload(!pageReload);
        }
      } catch (err) {
        setPageReload(!pageReload);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };
  // Video watch later and favourites
  const addFavorite = async (item, index) => {
    if (auth) {
      advaceData[index].myFavourite = item.myFavourite ? false : true;
      try {
        const { data } = await apiCall("POST", `/my-favourites/${item.id}`);
        if (data) {
          setPageReload(!pageReload);
        } else {
          setPageReload(!pageReload);
        }
      } catch (err) {
        setPageReload(!pageReload);
        console.log("err: ", err);
      }
    } else {
      // setGoogleLoginShow(true)
    }
  };

  async function PlaylistWatchLater(item, index) {
    try {
      if (auth) {
        advaceData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATER}/${item.id}`
        );
        if (data.length > 0) {
          setPageReload(!pageReload);
          setIsLoading(false);
        } else {
          setPageReload(!pageReload);
          setIsLoading(false);
        }
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setPageReload(!pageReload);
      setIsLoading(false);
    }
  }

  async function PlaylistWatchLaterRemove(item, index) {
    try {
      if (auth) {
        advaceData[index].watchLater = item.watchLater ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.PLAYLISTWATCHLATERREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setPageReload(!pageReload);
          setIsLoading(false);
        } else {
          setPageReload(!pageReload);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setPageReload(!pageReload);
      setIsLoading(false);
    }
  }

  async function myFavouritePlaylist(item, index) {
    try {
      if (auth) {
        advaceData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEPLAYLIST}/${item.id}`
        );
        if (data.length > 0) {
          setPageReload(!pageReload);
          setIsLoading(false);
        } else {
          setPageReload(!pageReload);
          setIsLoading(false);
        }
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setPageReload(!pageReload);
      setIsLoading(false);
    }
  }

  async function myFavouritePlaylistRemove(item, index) {
    try {
      if (auth) {
        advaceData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEPLAYLISTREMOVE}/${item.id}`
        );
        if (data.length > 0) {
          setPageReload(!pageReload);
          setIsLoading(false);
        } else {
          setPageReload(!pageReload);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setPageReload(!pageReload);
      setIsLoading(false);
    }
  }

  async function watchLaterSavePlayListFun(id) {
    if (auth) {
      try {
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.PLAYLISTWATCHLATERSAVE}/${id}`
        );
        if (data.length > 0) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }

  async function myFavouriteEbooksRemove(item, index) {
    try {
      if (auth) {
        if (item.contentType == "Transcript") {
          advaceData[index].myFavouriteTranscript = item.myFavouriteTranscript
            ? false
            : true;
        } else if (item.contentType == "Patra") {
          advaceData[index].myFavouritePatra = item.myFavouritePatra
            ? false
            : true;
        } else {
          advaceData[index].myFavouriteEbook = item.myFavouriteEbook
            ? false
            : true;
        }
        const { data } = await apiCall(
          "DELETE",
          `${item.contentType == "Transcript"
            ? ApiEndPoint.DELETEFAVOURITETRANSCRIPT
            : item.contentType == "Patra"
              ? ApiEndPoint.DELETEFAVOURITEPATRA
              : ApiEndPoint.DELETEFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbooks(item, index) {
    try {
      if (auth) {
        if (item.contentType == "Transcript") {
          advaceData[index].myFavouriteTranscript = item.myFavouriteTranscript
            ? false
            : true;
        } else if (item.contentType == "Patra") {
          advaceData[index].myFavouritePatra = item.myFavouritePatra
            ? false
            : true;
        } else {
          advaceData[index].myFavouriteEbook = item.myFavouriteEbook
            ? false
            : true;
        }
        const { data } = await apiCall(
          "POST",
          `${item.contentType == "Transcript"
            ? ApiEndPoint.TRANSCRIPTFAVOURITE
            : item.contentType == "Patra"
              ? ApiEndPoint.PATRAFAVOURITE
              : ApiEndPoint.EBOOKFAVOURITE
          }/${item.contentId}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbookSeries(item, index) {
    try {
      if (auth) {
        advaceData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.MYFAVOURITEADDEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbookSeriesRemove(item, index) {
    try {
      if (auth) {
        advaceData[index].favourite = item.favourite ? false : true;
        const { data } = await apiCall(
          "DELETE",
          `${ApiEndPoint.MYFAVOURITEREMOVEEBOOKSERIES}/${item.id}`
        );
        if (data.length > 0) {
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  }

  async function myFavouriteEbooksAdd(item, index) {
    try {
      if (auth) {
        advaceData[index].myFavourite = item.myFavourite ? false : true;
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKFAVOURITE}/${item.id}`
        );
        if (data.length > 0) {
          setPageReload(!pageReload);
          setIsLoading(false);
        } else {
          setPageReload(!pageReload);
          setIsLoading(false);
        }
      }
    } catch (err) {
      setPageReload(!pageReload);
      setIsLoading(false);
    }
  }

  const individualPlayListNav = (item, type) => {
    navigate("/individualPlayList", {
      state: { playListDetails: item, playListType: type },
    });
    watchLaterSavePlayListFun(item.id);
  };

  const handleDownloadClick = async (item, index) => {
    try {
      if (auth) {
        const params = {
          ebooksContents: item.contentType === "Ebook" ? item.contentId : "",
          patraContents: item.contentType === "Patra" ? item.contentId : "",
          transcriptContent:
            item.contentType === "Transcript" ? item.contentId : "",
        };

        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.EBOOKDOWNLOAD}`,
          params
        );
        if (data.status == 200) {
          fileDownload(item.contentUrl, index);
          setRefresh(!refresh);
          setIsLoading(false);
        } else {
          setRefresh(!refresh);
          setIsLoading(false);
        }
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
    }
  };

  function getFileExtension(url) {
    const parts = url?.split(".");
    return parts[parts.length - 1];
  }

  const [fileDownloadLoader, setFileDownloadLoader] = useState(null);
  const fileDownload = async (contentUrl, index) => {
    try {
      setFileDownloadLoader(index);
      let fileUrl = "";
      let fileExtension = getFileExtension(contentUrl);

      switch (fileExtension) {
        case "epub":
        case "pdf":
        case "mp3":
          fileUrl = contentUrl;
          break;
        default:
          console.error("Invalid file type:", fileExtension);
          return;
      }

      const response = await fetch(fileUrl);
      if (!response.ok) {
        setFileDownloadLoader(null);
        throw new Error(`Failed to download file (HTTP ${response.status})`);
      }
      let fileName = contentUrl?.substring(contentUrl.lastIndexOf("/") + 1);

      fileName = fileName?.replace(
        /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/,
        ""
      );

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setFileDownloadLoader(null);
      // Display a success toast message
      toast.success("File downloaded successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error("Error downloading the file:", error);

      // Display an error toast message
      toast.error("Error downloading the file. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleReadOrPlayClick = (contentUrl) => {
    if (contentType == "EBOOK_SYSTEM_PLAYLIST") {
      navigate(`/eBookSeries/${contentUrl}`);
    } else {
      const newTabUrl = `/fileViewer?contentUrl=${encodeURIComponent(
        contentUrl
      )}`;
      window.open(newTabUrl, "_blank");
    }
  };

  const handleVideoClick = (item) => {
    navigate(`/videoPlayPage/${item.id}`);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
  };

  const handleCheckboxChange2 = (event) => {
    const playlistId = parseInt(event.target.dataset.playlistId);
    const checked = event.target.checked;
    !isPlaylistSelected(playlistId)
      ? setCurrentlylySelectedUserPlaylistArray((prevSelectedPlaylist) => {
        let updatedSelectedPlaylist = [...prevSelectedPlaylist];
        if (checked) {
          if (
            !updatedSelectedPlaylist.some(
              (playlist) => playlist?.id === playlistId
            )
          ) {
            updatedSelectedPlaylist.push({ id: playlistId });
          }
        } else {
          updatedSelectedPlaylist = updatedSelectedPlaylist.filter(
            (playlist) => playlist.id !== playlistId
          );
        }
        return updatedSelectedPlaylist;
      })
      : removeFromExisting(playlistId);
  };
  const removeFromExisting = (playlistId) => {
    if (
      previouslySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updatedExistingPlaylist =
        previouslySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setPreviouslySelectedUserPlaylistArray(updatedExistingPlaylist);
      setUnselectedPlaylistArray((prev) => {
        return [...prev, { id: playlistId }];
      });
    } else if (
      currentlySelectedUserPlaylistArray.some(
        (playlist) => playlist.id === playlistId
      )
    ) {
      const updateSelectedPlaylistData =
        currentlySelectedUserPlaylistArray.filter(
          (playlist) => playlist.id !== playlistId
        );
      setCurrentlylySelectedUserPlaylistArray(updateSelectedPlaylistData);
    } else {
      return;
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
  };

  const generateDynamicMarksArray = (start, end, numElements) => {
    const marksArray = [];
    const step = (end - start) / (numElements - 1);

    for (let i = start; i <= end; i += step) {
      marksArray.push({ value: Math.round(i), label: `${Math.round(i)}m` });
    }

    return marksArray;
  };

  function takeSubcription(params) {
    params?.subscriptionName
      ? confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="card">
              <div className="info__padding">
                <h3>
                  {`Please Subscribe to ${params?.subscriptionName} plan to play this video. `}
                </h3>
                <h3>{!auth ? "Sign In To Subscribe..." : ""}</h3>
              </div>
              {!auth ? (
                <>
                  <div className="button__group">
                    <Button
                      onClick={() => {
                        onChange({ true: true });
                        onClose();
                      }}
                      variant="contained"
                      className="signBtnHome"
                    >
                      {/* <img className="g-icon" src={Google}></img> */}
                      Sign-in
                    </Button>
                    <button className="no-btn" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="button__group">
                    <Button
                      onClick={() => {
                        navigate("/subscriptionPlanList");
                        onClose();
                      }}
                      variant="contained"
                      className="subscriptionBtn"
                    // className="signBtnHome"
                    >
                      {/* <img className="g-icon" src={Google}></img> */}
                      Subscribe
                    </Button>
                    <button className="no-btn" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          );
        },
      })
      : confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="card">
                <div className="info__padding">
                  <h3>{`This Video is not yet available`}</h3>
                </div>
                <div className="button__group">
                  <button className="button btn-ok" onClick={onClose}>
                    Ok
                  </button>
                </div>
              </div>
            );
          },
        });
  }
  function takeOtherSubcription() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card">
            <div className="info__padding">
              <h3>{`Please sign in to use this feature. `}</h3>
              {/* <h3>{!auth ? "Sign In" : ""}</h3> */}
            </div>
            {!auth ? (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      onChange({ true: true });
                      onClose();
                    }}
                    variant="contained"
                    className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    <img
                      style={{ height: "23px", width: "23px" }}
                      className="g-icon"
                      src={Google}
                    ></img>
                    Sign-in
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="button__group">
                  <Button
                    onClick={() => {
                      navigate("/subscriptionPlanList");
                      onClose();
                    }}
                    variant="contained"
                    className="subscriptionBtn"
                    // className="signBtnHome"
                  >
                    {/* <img className="g-icon" src={Google}></img> */}
                    Subscribe
                  </Button>
                  <button className="no-btn" onClick={onClose}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }

  const getFavoriteStatus = (item) => {
    if (item.contentType === "Transcript") {
      return item.myFavouriteTranscript;
    } else if (item.contentType === "Patra") {
      return item.myFavouritePatra;
    } else {
      return item.myFavouriteEbook;
    }
  };

  const indianDateFormat = new Intl.DateTimeFormat("hi-IN", {
    year: "numeric",
    month: "long",
    day: "numeric",
    calendar: "indian",
  });

  const handleHinduCalStart = (date, index) => {
    if (index === "from") {
      setIndianCalndrFromDateObj(date);
      const updateArray = advaceFilterVal.map((item) => {
        if (item.contentType === advaceTabValue) {
          return {
            ...item,
            indianStartdate: indianDateFormat.format(date).toString(),
          };
        }
        return item;
      });
      if (contentType == "PAD") {
        setPadIndianCalndrFromDate(indianDateFormat.format(date).toString());
      } else if (contentType == "PATRA") {
        setIndianCalndrFromDate(indianDateFormat.format(date).toString());
      }
      setAdvaceFilterVal(updateArray);
    } else if (index === "to") {
      const updateArray = advaceFilterVal.map((item) => {
        if (item.contentType === advaceTabValue) {
          return {
            ...item,
            indianEndDate: indianDateFormat.format(date).toString(),
          };
        }
        return item;
      });
      setAdvaceFilterVal(updateArray);
      setIndianCalndrToDateObj(date);
      if (contentType == "PAD") {
        setPadIndianCalndrToDate(indianDateFormat.format(date).toString());
      } else if (contentType == "PATRA") {
        setIndianCalndrToDate(indianDateFormat.format(date).toString());
      }
    } else {
      return;
    }
  };
  function openLinkInNewTab(url) {
    window.open(url, "_blank");
  }




  const handleTitleClick = (item) => {
    setSingleTrack([])
    setTrack([])
    setPlayNextTrack([])
    setPlayingShuffledAlbumId(null)
    setPlayingAlbumId(null)
    setPlayingAlbumId(null);
    let trackList = [];
    trackList[0] = item;
    setTrack(trackList);
    setIsMusicPlayerVisible(true);
  };

  const formatDuration = (duration) => {

    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  async function toggleFavoriteTrack(item, index) {
    try {
      if (auth) {
        const isCurrentlyFavorite = item.isFavourite;
        const method = isCurrentlyFavorite ? "DELETE" : "POST";
        const endpoint = isCurrentlyFavorite
          ? `${apiEndPoints.TRACKSFAVORITEDELETE}/${item.id}`
          : `${apiEndPoints.TRACKSFAVORITE}/${item.id}`;
        advaceData[index].isFavourite = !isCurrentlyFavorite;
        const data = await apiCall(method, endpoint);
        setRefresh(!refresh);
        setIsLoading(false);
        const message = isCurrentlyFavorite
          ? "Removed from favorites successfully!"
          : "Added to favorites successfully!";
        // toast.success(message);
      } else {
        takeOtherSubcription();
      }
    } catch (err) {
      setRefresh(!refresh);
      setIsLoading(false);
      toast.error("Something went wrong, try again");
    }
  }
  const openModal = (id) => {
    setIsOpen(true);
    setCurrentlyPlaylingVidId(id)

  };
  const closeModal = () => {
    setPreviouslySelectedUserPlaylistArray([]);
    setCurrentlylySelectedUserPlaylistArray([]);
    setUnselectedPlaylistArray([]);
    setIsOpen(false);
  };
  const isPlaylistSelected = (playlistId) => {
    return (
      previouslySelectedUserPlaylistArray.some(
        (item) => item?.id == playlistId
      ) ||
      currentlySelectedUserPlaylistArray.some((item) => item?.id === playlistId)
    );
  };
  const fetchMoreUserPlaylistData = () => {
    userPlayListFun();
  };
  async function userPlayListFun(fetchPage = 0) {
    if (auth) {
      try {
        const params = {
          searchValue: '',
          sortColumn: "A_TO_Z",
          pageNo: fetchPage ? fetchPage - 1 : pageNo,
          pageSize: 8,
          sortDirection: ''
        };
        setIsLoading(true);
        const { data } = await apiCall(
          "POST",
          `${ApiEndPoint.USERPLAYLISTFORAUDIO}`,
          params
        );
        if (data.length > 0) {
          setIsLoading(false);
          setUserPlayList(fetchPage ? data : prev => [...prev, ...data]);
          setUserPlayList(
            RemoveDuplicates(fetchPage ? data : userPlayList.concat(data))
          );
          setPageNo(fetchPage ? fetchPage : pageNo + 1);
          setScrollHashMap(true);
          setScrollLast(true);
        } else {
          setIsLoading(false);
          setScrollLast(false);
          fetchPage && setUserPlayList([]);
          setPageNo(0);
          setScrollHashMap(false);
        }
      } catch (err) {
        setIsLoading(false);
        // setScrollLast(false);
      }
    }
  }
  const createPlaylist = () => {
    setIsOpen(false);
    setShowCreatePlayListModel(true);
  };
  async function getSelectedPlaylistData() {
    if (auth) {
      try {
        setIsLoading(true);
        const { data } = await apiCall(
          "GET",
          `${ApiEndPoint.GETCHECKEDCHECKBOXFORAUDIO}/${currentlyPlaylingVidId}`
        );
        if (data.length > 0) {
          setIsLoading(false);
          setPreviouslySelectedUserPlaylistArray(data);
        } else {
          setPreviouslySelectedUserPlaylistArray([]);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
  }
  async function addVideoAgainstPlaylist() {
    if (auth) {
      if (
        currentlySelectedUserPlaylistArray.length > 0 ||
        unselectedPlaylistArray.length > 0
      ) {
        try {
          const params = {
            videoId: currentlyPlaylingVidId,
            selected: currentlySelectedUserPlaylistArray,
            unSelected: unselectedPlaylistArray,
            contentType: 'YOUTUBE_TRACK'
          };
          setIsLoading(true);
          const { status } = await apiCall(
            "POST",
            `${ApiEndPoint.ADDAUDIOAGAINSTMULTIPLEPLAYLIST}`,
            params
          );
          if (status === 200) {
            setIsLoading(false);
            toast.success("Added successfully");
            closeModal();
          } else {
            setIsLoading(false);
            toast.error("Something went wrong , try again");
          }
        } catch (err) {
          setIsLoading(false);
        }
      }
    }
  }
  const openCreatedPlayListModal = () => {
    setShowCreatePlayListModel(true);
  };
  const closeCreatedPlayListModal = () => {
    setShowCreatePlayListModel(false);
  };
  const handlePlaylistNameChange = (event) => {
    const inputValue = event.target.value;
    if (validateInput(inputValue)) {
      setPlaylistName(inputValue);
      setIsValidPlaylistName(true);
    } else {
      setIsValidPlaylistName(false);
    }
  };
  const validateInput = (text, isNote = false) => {
    const emojiRegexs = emojiRegex;
    if (/^\s+/.test(text)) {
      return false;
    } else if (emojiRegexs.test(text)) {
      return false;
    } else if (!isNote && text.length > 35) {
      return false;
    } else return true;
  };
  const handleSubmit = async (event) => {
    const valid = validateForm();
    if (auth) {
      if (valid) {
        const params = {
          playListName: playlistName,
          contentType: 'YOUTUBE_TRACK'
        };
        setPlaylistBtnLding(true);
        try {
          const { data } = await apiCall(
            "POST",
            `${ApiEndPoint.CREATEUSERPLAYLIST}`,
            params
          );
          if (data) {
            setShowCreatePlayListModel(false);
            setPlaylistName(null);
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
            openModal(currentlyPlaylingVidId);
            fetchUserPlaylistData();
          } else {
            setRefresh(!refresh);
            setPlaylistBtnLding(false);
          }
        } catch (err) {
          setShowCreatePlayListModel(false);
          setRefresh(!refresh);
          setPlaylistBtnLding(false);
        }
      } else {
        setIsValidPlaylistName(true);
      }
    } else {
    }
    // setIsOpen(false);
  };
  const validateForm = () => {
    if (playlistName == "") {
      return false;
    }
    return true;
  };
  const fetchUserPlaylistData = () => {
    userPlayListFun(1);
  };
  useEffect(() => {
    if (isOpen) {
      fetchUserPlaylistData();
      getSelectedPlaylistData();
    }
  }, [isOpen]);
  //const openPopup = (id) => {
  //    setPopupVisible(id);
  //  };
  const togglePopup = (id) => {
    // setIsMoreOpen(true);
    setPopupVisible(isPopupVisible === id ? null : id);
  }

  const closePopup = () => {
    setPopupVisible(null);
  };

  // const handlePlayNext = (trackDetails) => {
  //   if (!isMusicPlayerVisible) {
  //     toast.warn("No Bhakti pad is playing.");
  //     setPopupVisible(null);
  //     return;
  //   }
  
  //   if (trackDetails) {
  //     const newTrackList = [...track];      
  //     newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
  //     setTrack(newTrackList);
  //   }
  
  //   setPopupVisible(null);
  //   toast.success("Bhakti Pad will Play Next.");
  // };

  const handlePlayNext = (trackDetails) => {
    if (!isMusicPlayerVisible) {
      toast.warn("No Bhakti pad is playing.");
      setPopupVisible(null);
      return;
    }  
    // const trackDetails = musicData?.find(track => track?.id == id);
    if (trackDetails) {
      let newTrackList = [...track];
      const existingTrackIndex = newTrackList.findIndex(track => track.id === trackDetails?.id);
      if (existingTrackIndex !== -1) {
        newTrackList.splice(existingTrackIndex, 1);
      }
      newTrackList.splice(currentTrackIndex + 1, 0, trackDetails);
      setTrack(newTrackList);
      dispatch(addTrack(newTrackList));
      toast.success("Bhakti Pad will Play Next.");
    }
    setPopupVisible(null);
  };

  const handleCopyLink = (id) => {
    const trackDetails = advaceData?.find(track => track?.id == id);
    setTrackDetailsForShare(trackDetails?.audioAlbum?.id)
    // setPopupVisible(null);
  };
  const openModalSortBy = () => {
    setIsOpenSortBy(true);
  };
  const closeModalSortBy = () => {
    setIsOpenSortBy(false);
  };

  //


  const handleFavoriteTracks = async (item, type, action) => {
    try {
      if (auth) {
        setAdvaceData((prevMusicData) => {
          const updatedMusicData = [...prevMusicData];
          const albumIndex = updatedMusicData.findIndex((x) => x.id === item.id);
          if (albumIndex !== -1) {
            updatedMusicData[albumIndex].isFavourite = !updatedMusicData[albumIndex].isFavourite;
          }
          return updatedMusicData;
        });

        const apiUrl = `${action === 'add' ? apiEndPoints.ALBUMSFAVORITE : apiEndPoints.ALBUMSFAVORITEDELETE}/${item.id}`;
        const method = action === 'add' ? 'POST' : 'DELETE';

        const response = await apiCall(method, apiUrl);
        if (response) {
        }
      }
      else {
        takeOtherSubcription();
      }

    } catch (error) {
      console.error('Error updating favorite tracks:', error);
    }
  };

  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }

  return (
    <>
      {/*----- Mobile Header Start -----*/}
      <div className="mobile-search-header">
        <div style={{ display: "flex" }}>
          <img
            src={AllImages.YellowArrow}
            alt="Back Arrow"
            title="Back Arrow"
            className="back-arrow"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <div className="mobile-search-wrap">
            <div className="mobile-search-input">
              <input
                className="f-poppins"
                type="text"
                placeholder="Search for Video"
                onChange={handleSearchBox}
                value={searchValue}
              />
              <span
                style={{ cursor: "pointer" }}
                onClick={() => advacePageFilterFun(1)}
                className="mobile-search-icon"
              >
                <img src={AllImages.Search} alt="Search" title="Search" />
              </span>
            </div>
            <div
              style={{ cursor: "pointer" }}
              className="mobile-filter-icon"
              onClick={() => openAdvanceModal()}
            >
              <img src={AllImages.SearchToggle2} alt="Search" title="Search" />
            </div>
          </div>
        </div>
      </div>
      {/*----- Mobile Header End -----*/}

      <section className="advance-search">
        <div>
          {/*----- Videos, Playlists, Ebook, Patra and Pad Tab Bar -----*/}
          <Box className="advance-search-tab-a-center">
            <Tabs
              value={advaceTabValue}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#FFB200",
                  minWidth: "auto",
                },
              }}
              aria-label="secondary tabs example"
            >
              <StyledTab value="VIDEO" label="Satsang" disabled={!tabActive} />
              <StyledTab value="SYSTEM_PLAYLIST" label="Satsang Playlist" disabled={!tabActive} />
              <StyledTab value="YOUTUBE_TRACK" label="Bhakti Pad" disabled={!tabActive} />
              <StyledTab value="YOUTUBE_ALBUM" label="Album" disabled={!tabActive} />
              <StyledTab value="EBOOK" label="E-BOOKS" disabled={!tabActive} />
              <StyledTab value="EBOOK_SYSTEM_PLAYLIST" label="E-BOOKS Series" disabled={!tabActive} />
              <StyledTab value="PATRA" label="Patra" disabled={!tabActive} />
              <StyledTab value="PAD" label="Pad" disabled={!tabActive} />
              <StyledTab value="TRANSCRIPT" label="Transcript" disabled={!tabActive} />

            </Tabs>
          </Box>

          {/*----- MY Created Playlists Start -----*/}
          {advaceTabValue == "VIDEO" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        {item.videoUrl ? (
                          <div className="advance-search-card-wrap">
                            <div className="advance-search-inside-wrap">
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => handleVideoClick(item)}
                                className="advance-search-list-image"
                              >
                                <img
                                  className={`advance-search-img-banner ${getImageNameFromResolution(
                                    item.thumbnails
                                  ) != "maxresdefault" && "image-resolution"
                                    }`}
                                  src={item.thumbnails}
                                  key={item.id}
                                  alt="Card Image"
                                />
                                <img
                                  className="advance-search-play-icon"
                                  src={AllImages?.PlayIcon}
                                  alt="Play Icon"
                                  title="Play Icon"
                                />
                              </div>
                              <div className="advance-search-card-content-item">
                                <div className="advance-search-video-content">
                                  <div>
                                    <p className="advance-search-title-text f-poppins">
                                      {item.videoTitle}
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {MinutesToHoursMinutes(
                                        item.videoDuration
                                      )}{" "}
                                      hrs
                                    </p>
                                  </div>
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    <img
                                      onClick={() =>
                                        item?.watchLater
                                          ? RemoveWatchLaterVideo(item, index)
                                          : videoWatchLater(item, index)
                                      }
                                      src={
                                        item?.watchLater
                                          ? AllImages.SelectedTimer
                                          : AllImages.Timer
                                      }
                                      style={{ cursor: "pointer" }}
                                      alt="Timer"
                                      title="Timer"
                                    ></img>
                                    <img
                                      src={
                                        item?.myFavourite
                                          ? AllImages.Favorite_sel
                                          : AllImages.Fav
                                      }
                                      onClick={() => addFavorite(item, index)}
                                      alt="Favourite"
                                      style={{ cursor: "pointer" }}
                                      title="Favourite"
                                    ></img>
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                        title: "Video Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          //Lock video full card
                          <div className="advance-search-card-wrap">
                            <div
                              onClick={() => takeSubcription(item)}
                              className="advance-search-inside-wrap"
                            >
                              <div>
                                <div className="advance-search-list-image">
                                  <img
                                    className={`advance-search-img-banner ${getImageNameFromResolution(
                                      item.thumbnails
                                    ) != "maxresdefault" && "image-resolution"
                                      }`}
                                    src={item.thumbnails}
                                    key={item.id}
                                    alt="Card Image"
                                  />
                                  <img
                                    className="advance-search-play-icon"
                                    src={AllImages?.PlayIcon}
                                    alt="Play Icon"
                                    title="Play Icon"
                                  />
                                </div>
                                <div className="layerImage"></div>
                              </div>
                              <div className="star1 badgestarInvisible">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubcription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe for more Videos"}
                                </span>
                              </div>
                              <div className="advance-search-card-content-item">
                                <div className="advance-search-video-content">
                                  <div>
                                    <p className="advance-search-title-text f-poppins">
                                      {item.videoTitle}
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {MinutesToHoursMinutes(
                                        item.videoDuration
                                      )}{" "}
                                      hrs
                                    </p>
                                  </div>
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    <img
                                      onClick={() =>
                                        item?.watchLater
                                          ? RemoveWatchLaterVideo(item, index)
                                          : videoWatchLater(item, index)
                                      }
                                      src={
                                        item?.watchLater
                                          ? AllImages.SelectedTimer
                                          : AllImages.Timer
                                      }
                                      alt="Timer"
                                      style={{ cursor: "pointer" }}
                                      title="Timer"
                                    ></img>
                                    <img
                                      src={
                                        item?.myFavourite
                                          ? AllImages.Favorite_sel
                                          : AllImages.Fav
                                      }
                                      onClick={() => addFavorite(item, index)}
                                      alt="Favourite"
                                      style={{ cursor: "pointer" }}
                                      title="Favourite"
                                    ></img>
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/videoPlayPage/${item.id}`,
                                        title: "Video Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "SYSTEM_PLAYLIST" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div
                              onClick={() => {
                                individualPlayListNav(item, "admin");
                              }}
                              style={{ cursor: "pointer" }}
                              className="advance-search-list-image"
                            >
                              <img
                                className="advance-search-img-banner"
                                src={`${GLOBAL_URL}/system/playlist/thumbnail/${item.id}`}
                                key={item.id}
                                alt="Card Image"
                              />
                              <img
                                className="advance-search-play-icon"
                                src={AllImages?.PlayIcon}
                                alt="Play Icon"
                                title="Play Icon"
                              />
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.playlistName}
                                  </p>
                                  <div
                                    style={{ gap: "10px" }}
                                    className="general-textDetails general-text-hrs f-poppins"
                                  >
                                    <p className="general-text-hrs">
                                      {/* {item.videoDuration}{" "} */}
                                      {item.videoDuration
                                        ? `${item.videoDuration} hrs`
                                        : "0 hrs"}
                                    </p>
                                    <p className="general-text-hrs">•</p>
                                    <p className="general-text-hrs">
                                      {/* {item.noOfVideos}{" "} */}
                                      {item.noOfVideos
                                        ? `${item.noOfVideos} videos`
                                        : "0 videos"}
                                    </p>
                                  </div>
                                </div>
                                <div className="advance-search-mt-10 advance-search-ta-left">
                                  <img
                                    onClick={() =>
                                      item.watchLater
                                        ? PlaylistWatchLaterRemove(item, index)
                                        : PlaylistWatchLater(item, index)
                                    }
                                    src={
                                      item.watchLater
                                        ? AllImages.SelectedTimer
                                        : AllImages?.Timer
                                    }
                                    alt="Time"
                                    style={{ cursor: "pointer" }}
                                    title="Time"
                                  ></img>
                                  <img
                                    onClick={() =>
                                      item.favourite
                                        ? myFavouritePlaylistRemove(item, index)
                                        : myFavouritePlaylist(item, index)
                                    }
                                    src={
                                      item.favourite
                                        ? AllImages.Favorite_sel
                                        : AllImages?.Fav
                                    }
                                    style={{ cursor: "pointer" }}
                                    alt="Favourite"
                                    title="Favourite"
                                  ></img>
                                  {/* <img src={AllImages.Copy} alt="Copy" title="Copy"></img> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "EBOOK" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advanceEbbok advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div className="advance-search-list-image">
                              <img
                                className="advance-search-img-banner"
                                src={item.thumbnailImageUrl}
                                key={item.id}
                                alt="Card Image"
                              />
                              {/* <img className="advance-search-play-icon" src={AllImages?.PlayIcon} alt="Play Icon" title="Play Icon" /> */}
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.title}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                  >
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {item.pageCount} Pages
                                    </p>
                                    {/* <p className="advance-search-text-hrs f-poppins">
                                      •
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {DateTimeAgo(item.publishDateTime)}
                                    </p> */}
                                  </div>
                                </div>
                                <div className="ebook-icon-read-div">
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                          item?.contentUrl?.replace(/ /g, "%20")
                                        )}`,
                                        title: "Ebook Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          getFavoriteStatus(item)
                                            ? myFavouriteEbooksRemove(
                                                item,
                                                index
                                              )
                                            : myFavouriteEbooks(item, index)
                                        }
                                        src={
                                          getFavoriteStatus(item)
                                            ? AllImages.Favorite_sel
                                            : AllImages?.Fav
                                        }
                                      ></img>
                                      <span className="tooltiptext">
                                        {!getFavoriteStatus(item)
                                          ? "Mark Favorite"
                                          : "Unfavorite"}
                                      </span>
                                    </div>
                                    {fileDownloadLoader == index ? (
                                      <img
                                        src={loader}
                                        alt="loading..."
                                        style={{ height: 20, width: 20 }}
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDownloadClick(item, index)
                                        }
                                        src={AllImages.Download}
                                        alt="Download"
                                        title="Download"
                                      ></img>
                                    )}
                                  </div>
                                  <div
                                    class="mt-10"
                                    onClick={() =>
                                      handleReadOrPlayClick(item.contentUrl)
                                    }
                                  >
                                    <p className="ebook-read">Read</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "EBOOK_SYSTEM_PLAYLIST" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advanceEbbok advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div className="advance-search-list-image">
                              <img
                                className="advance-search-img-banner"
                                src={item.thumbnail}
                                key={item.id}
                                alt="Card Image"
                              />
                              {/* <img className="advance-search-play-icon" src={AllImages?.PlayIcon} alt="Play Icon" title="Play Icon" /> */}
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.playlistName}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                  >
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {item.ebookCount
                                        ? item.ebookCount
                                        : 0}{" "}
                                      E-Books
                                    </p>
                                    {/* <p className="advance-search-text-hrs f-poppins">
                                      •
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {DateTimeAgo(item.publishDateTime)}
                                    </p> */}
                                  </div>
                                </div>
                                <div className="ebook-icon-read-div">
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `   ${VideoPlaylistShareUrl}/eBookSeries/${item.id}`,

                                        title: "Ebook Series Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          item.favourite
                                            ? myFavouriteEbookSeriesRemove(
                                                item,
                                                index
                                              )
                                            : myFavouriteEbookSeries(
                                                item,
                                                index
                                              )
                                        }
                                        src={
                                          item.favourite
                                            ? AllImages.Favorite_sel
                                            : AllImages?.Fav
                                        }
                                      ></img>
                                      <span className="tooltiptext">
                                        {!item.favourite
                                          ? "Mark Favorite"
                                          : "Unfavorite"}
                                      </span>
                                    </div>
                                    {/* {fileDownloadLoader == index ? (
                                      <img
                                        src={loader}
                                        alt="loading..."
                                        style={{ height: 20, width: 20 }}
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDownloadClick(item, index)
                                        }
                                        src={AllImages.Download}
                                        alt="Download"
                                        title="Download"
                                      ></img>
                                    )} */}
                                  </div>
                                  <div
                                    class="mt-10"
                                    onClick={() =>
                                      handleReadOrPlayClick(item.id)
                                    }
                                  >
                                    <p className="ebook-read">Open</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "PATRA" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advanceEbbok advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div className="advance-search-list-image">
                              <img
                                className="advance-search-img-banner"
                                src={item.thumbnailImageUrl}
                                key={item.id}
                                alt="Card Image"
                              />
                              {/* <img className="advance-search-play-icon" src={AllImages?.PlayIcon} alt="Play Icon" title="Play Icon" /> */}
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.title}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                  >
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {item.pageCount} Pages
                                    </p>
                                    {/* <p className="advance-search-text-hrs f-poppins">
                                      •
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {DateTimeAgo(item.publishDateTime)}
                                    </p> */}
                                  </div>
                                </div>
                                <div className="ebook-icon-read-div">
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    {/* <img src={item.myFavourite ? AllImages.Favorite_sel : AllImages?.Fav} alt="Favourite" title="Favourite"></img> */}
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                          item?.contentUrl?.replace(/ /g, "%20")
                                        )}`,
                                        title: "Patra Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          getFavoriteStatus(item)
                                            ? myFavouriteEbooksRemove(
                                                item,
                                                index
                                              )
                                            : myFavouriteEbooks(item, index)
                                        }
                                        src={
                                          getFavoriteStatus(item)
                                            ? AllImages.Favorite_sel
                                            : AllImages?.Fav
                                        }
                                      ></img>
                                      <span className="tooltiptext">
                                        {!getFavoriteStatus(item)
                                          ? "Mark Favorite"
                                          : "Unfavorite"}
                                      </span>
                                    </div>
                                    {fileDownloadLoader == index ? (
                                      <img
                                        src={loader}
                                        alt="loading..."
                                        style={{ height: 20, width: 20 }}
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDownloadClick(item, index)
                                        }
                                        src={AllImages.Download}
                                        alt="Download"
                                        title="Download"
                                      ></img>
                                    )}
                                  </div>
                                  <div
                                    class="mt-10"
                                    onClick={() =>
                                      handleReadOrPlayClick(item.contentUrl)
                                    }
                                  >
                                    <p className="ebook-read">Read</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}
          {advaceTabValue == "PAD" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advanceEbbok advancePad advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div className="advance-search-list-image">
                              <img
                                className="advance-search-img-banner"
                                src={item.thumbnailImageUrl}
                                key={item.id}
                                alt="Card Image"
                              />
                              {/* <img className="advance-search-play-icon" src={AllImages?.PlayIcon} alt="Play Icon" title="Play Icon" /> */}
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.title}
                                  </p>

                                  {/* <p className="advance-search-text-hrs f-poppins">
                                    {" "}
                                    {DateTimeAgo(item.publishDateTime)}
                                  </p> */}
                                </div>
                                <div className="ebook-icon-read-div">
                                  <div
                                    onClick={() => handleShareDialogue(item)}
                                    className="advance-search-ta-left"
                                  >
                                    <img
                                      src={AllImages.Copy}
                                      alt="Copy"
                                      title="Copy"
                                      style={{ cursor: "pointer" }}
                                    ></img>
                                  </div>

                                  <div>
                                    {item?.contentUrlApple && (
                                      <img
                                        src={AllImages?.appleMusicSvg}
                                        className="musicIcons read-play-type-btn"
                                        onClick={() =>
                                          openLinkInNewTab(
                                            item?.contentUrlApple
                                          )
                                        }
                                        alt="Apple Music"
                                        title="Apple Music"
                                      />
                                    )}
                                    {item?.contentUrlSpotify && (
                                      <img
                                        src={AllImages?.spotifyMusicSvg}
                                        className="musicIcons read-play-type-btn"
                                        onClick={() =>
                                          openLinkInNewTab(
                                            item.contentUrlSpotify
                                          )
                                        }
                                        alt="Spotify"
                                        title="Spotify"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "TRANSCRIPT" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                // hasMore={true}
                hasMore={scrollHashMap}
                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                      src={loader}
                      alt="loading..."
                      style={{ height: 25, width: 25, marginTop: 20 }}
                    /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >
                <div className="advanceEbbok advance-search-col-count-3 custom-row">
                  {advaceData.length > 0 ? (
                    advaceData?.map((item, index) => (
                      <div key={index} className="custom-col-4 custom-col">
                        <div className="advance-search-card-wrap">
                          <div className="advance-search-inside-wrap">
                            <div className="advance-search-list-image">
                              <img
                                className="advance-search-img-banner"
                                src={item.thumbnailImageUrl}
                                key={item.id}
                                alt="Card Image"
                              />
                              {/* <img className="advance-search-play-icon" src={AllImages?.PlayIcon} alt="Play Icon" title="Play Icon" /> */}
                            </div>
                            <div className="advance-search-card-content-item">
                              <div className="advance-search-video-content">
                                <div>
                                  <p className="advance-search-title-text f-poppins">
                                    {item.title}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10px",
                                    }}
                                  >
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {item.pageCount} Pages
                                    </p>
                                    {/* <p className="advance-search-text-hrs f-poppins">
                                      •
                                    </p>
                                    <p className="advance-search-text-hrs f-poppins">
                                      {" "}
                                      {DateTimeAgo(item.publishDateTime)}
                                    </p> */}
                                  </div>
                                </div>
                                <div className="ebook-icon-read-div">
                                  <div className="advance-search-mt-10 advance-search-ta-left">
                                    {/* <img src={item.myFavourite ? AllImages.Favorite_sel : AllImages?.Fav} alt="Favourite" title="Favourite"></img> */}
                                    <RWebShare
                                      data={{
                                        // text: "Like humans, flamingos make friends for life",
                                        url: `${VideoPlaylistShareUrl}/fileViewer?contentUrl=${encodeURIComponent(
                                          item?.contentUrl?.replace(/ /g, "%20")
                                        )}`,
                                        title: "Transcript Share",
                                      }}
                                      onClick={() => console.log("share")}
                                    >
                                      <img
                                        src={AllImages.Copy}
                                        alt="Copy"
                                        title="Copy"
                                        style={{ cursor: "pointer" }}
                                      ></img>
                                    </RWebShare>
                                    <div className="favTooltip">
                                      <img
                                        onClick={() =>
                                          getFavoriteStatus(item)
                                            ? myFavouriteEbooksRemove(
                                                item,
                                                index
                                              )
                                            : myFavouriteEbooks(item, index)
                                        }
                                        src={
                                          getFavoriteStatus(item)
                                            ? AllImages.Favorite_sel
                                            : AllImages?.Fav
                                        }
                                      ></img>
                                      <span className="tooltiptext">
                                        {!getFavoriteStatus(item)
                                          ? "Mark Favorite"
                                          : "Unfavorite"}
                                      </span>
                                    </div>
                                    {fileDownloadLoader == index ? (
                                      <img
                                        src={loader}
                                        alt="loading..."
                                        style={{ height: 20, width: 20 }}
                                      />
                                    ) : (
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDownloadClick(item, index)
                                        }
                                        src={AllImages.Download}
                                        alt="Download"
                                        title="Download"
                                      ></img>
                                    )}
                                  </div>
                                  <div
                                    onClick={() =>
                                      handleReadOrPlayClick(item.contentUrl)
                                    }
                                  >
                                    <p className="ebook-read">Read</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : dataNoFound ? (
                    <NoDataAvailable />
                  ) : (
                    <div className="loader">
                      <img
                        src={loader}
                        alt="loading..."
                        style={{ height: 35, width: 35, marginTop: 20 }}
                      />
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          )}




          {advaceTabValue === "YOUTUBE_TRACK" && (
            <div className="container watch-later advance-search-mt-20">
              <InfiniteScroll
                dataLength={advaceData?.length}
                next={() => advanceSearchInfiniteScroll(advaceTabValue)}
                hasMore={scrollHashMap}

                loader={
                  scrollLast ? (
                    <center>
                      {/* <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 25, width: 25, marginTop: 20 }}
                  /> */}
                    </center>
                  ) : (
                    <p className="subtitle fancy">
                      <span>End of results</span>
                    </p>
                  )
                }
              >

                <Table responsive striped>
                  <thead className="mobHide">
                    <tr>
                      <th>No.</th>
                      <th>Title</th>
                      <th className="none">Album</th>
                      <th className="none">Duration</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="mobHide">
                    {advaceData.length > 0 ? (advaceData.map((item, index) => (
                      <tr key={item.id} className="tableHover">
                        <td>
                          <div className="srNo"
                            onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                          >
                            <p className="no mobHide">{index + 1}</p>
                            <img src={item?.thumbnailUrl} class="bhaktiImg" alt="img" />
                            {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                              <div className="bhaktipadPlayIconTooltip">
                                <img src={AllImages?.PlayIcon} class="trackPlayButton" alt="img" />
                                <div className="bhaktipadPlayIconTooltipInner">
                                  <span className="tooltiptext">
                                    {"Play Bhakti Pad"}
                                  </span>
                                </div>
                              </div> :
                              <div className="star1 badgestarInvisible tableLock">
                                <div className="LikeCount badgeShow">
                                  <i
                                    className="fa fa-lock"
                                    onClick={() => takeSubscription(item)}
                                  ></i>
                                </div>
                                <span className="tooltiptext">
                                  {"Subscribe to use this feature"}
                                </span>
                              </div>
                            }
                          </div>
                        </td>
                        <td>
                          <div className="titleSection"
                            onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handleTitleClick(item) : takeSubscription(item) }}
                          >
                            <p className="title">{item.title}</p>
                            {item.albumTitle && <p className="subTitle">{item.albumTitle}</p>}
                          </div>
                        </td>
                        <td className="none">
                          {item?.audioAlbum?.title && <p className="srNo">{item?.audioAlbum?.title}</p>}
                        </td>
                        <td className="none">
                          <p className="duration">
                            {item.duration ? formatDuration(item.duration) : 'N/A'}
                          </p>
                        </td>
                        <td>
                          <div className="actions">
                            <div className="favTooltip">
                              <img
                                onClick={() => toggleFavoriteTrack(item, index)}
                                src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                alt="IMG"
                              ></img>
                              <span className="tooltiptext">
                                {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                              </span>
                            </div>
                            <div className="favTooltip">
                              <img
                                className="video-play-mr-10 mobHide"
                                alt="img"
                                src={AllImages?.TablePlus}
                                onClick={() => { auth ? openModal(item?.id) : takeOtherSubcription() }}
                              // onClick={() => openModal(item?.id)}
                              ></img>
                              <span className="tooltiptext">
                                {"Add to My Playlists"}
                              </span>
                            </div>
                            {/* <div key={item.id} className="popupOuter">
                              <img
                                src={AllImages?.More}
                                className="moreImg"
                                alt="img"
                                onClick={() => togglePopup(item?.id)}
                              />
                              <span className="tooltiptext">
                                {"View More"}
                              </span>
                            </div> */}
                            <div key={item.id} className="popupOuter moreTooltip">
                              <img
                                src={AllImages?.More}
                                className="moreImg"
                                alt="img"
                                onClick={() => togglePopup(item?.id)}
                              />
                              <div className="moreTooltipInner">
                                <span className="tooltiptext">
                                  {"View More"}
                                </span>
                              </div>
                            </div>
                          </div>
                          {isPopupVisible === item.id && (

                            !isMobileView ? (
                              <div className="morePopup" ref={popupRef} >
                                <div className="outer"
                                  // onClick={() => handlePlayNext(item.id)}
                                  onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? handlePlayNext(item) : takeSubscription(item) }}
                                >
                                  <img src={AllImages?.Next} className="more" alt="img" />
                                  <p className="label">Play Next</p>
                                </div>
                                <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                  <RWebShare
                                    data={{
                                      url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                      title: 'Bhakti Pad Share',
                                    }}
                                    onClick={() => {
                                      handleCopyLink(item.id);
                                      console.log('share');
                                    }}
                                  >
                                    <div className="copyTooltip">
                                      <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                      <span className="tooltiptext">Copy</span>
                                    </div>
                                  </RWebShare>
                                  <div>
                                    <RWebShare
                                      data={{
                                        url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                        title: 'Bhakti Pad Share',
                                      }}
                                      onClick={() => {
                                        handleCopyLink(item.id);
                                        console.log('share');
                                      }}
                                    >
                                      <p className="label">Copy Link</p>
                                    </RWebShare>
                                  </div>
                                </div>
                              </div>

                            ) : (
                              <div className="mobileViewDialogOuter" ref={popupRef}>
                                <Dialog
                                  open={togglePopup}
                                  className="sortBy mobileViewDialog"
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  ref={popupRef}
                                >
                                  <DialogContent
                                    style={{
                                      overflowY: "hidden",
                                      overflowX: "hidden",
                                    }}
                                    ref={popupRef}
                                  >
                                    <div className="qu-history-modal-msg-content" ref={popupRef}>
                                      <p className="qu-history-modal-msg-text">{"More Option"}</p>
                                      <span
                                        className="qu-history-modal-close-button"
                                        onClick={togglePopup}
                                      >
                                        &times;
                                      </span>
                                    </div>
                                    <div class="actionPopupMob" ref={popupRef}>
                                      <div class="outer tabHide"
                                        ref={popupRef}
                                        onClick={() => toggleFavoriteTrack(item, index)}>
                                        <img
                                          ref={popupRef}
                                          src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Fav}
                                          alt="IMG"
                                        ></img>
                                        <p class="label">Add to Favourites</p>
                                      </div>
                                      <div class="outer tabHide" ref={popupRef} onClick={() => openModal(item?.id)}>
                                        <img
                                          className="plus"
                                          alt="img"
                                          src={AllImages?.TablePlus}
                                        />
                                        <p class="label">Add to Playlist</p>
                                      </div>
                                      <div className="outer" ref={popupRef} onClick={() => handlePlayNext(item.id)}>
                                        <img src={AllImages?.Next} className="more" alt="img" />
                                        <p className="label">Play Next</p>
                                      </div>
                                      <div className="outer" onClick={() => handleCopyLink(item.id)}>
                                        <RWebShare
                                          data={{
                                            url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                            title: 'Bhakti Pad Share',
                                          }}
                                          onClick={() => {
                                            handleCopyLink(item.id);
                                            console.log('share');
                                          }}
                                        >
                                          <div className="copyTooltip">
                                            <img src={AllImages?.CopyGray} className="more" id="myCopy" alt="img" />
                                            <span className="tooltiptext">Copy</span>
                                          </div>
                                        </RWebShare>
                                        <div>
                                          <RWebShare
                                            data={{
                                              url: `${VideoPlaylistShareUrl}/individualBhaktipad/${item?.id}`,
                                              title: 'Bhakti Pad Share',
                                            }}
                                            onClick={() => {
                                              handleCopyLink(item.id);
                                              console.log('share');
                                            }}
                                          >
                                            <p className="label">Copy Link</p>
                                          </RWebShare>
                                        </div>
                                      </div>
                                    </div>
                                  </DialogContent>
                                </Dialog>
                              </div>
                            )

                          )}
                        </td>
                      </tr>
                    ))
                    ) : dataNoFound ? (
                      <tr>
                        <td colSpan="5">
                          <NoDataAvailable />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="5">
                          <div className="loader">
                            <img
                              src={loader}
                              alt="loading..."
                              style={{ height: 35, width: 35, marginTop: 20 }}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
          )}

          {advaceTabValue == "YOUTUBE_ALBUM" && (
            <div className={(advaceData?.length > 0) ? "albumOuter" : "albumOuter noData"}>
              {advaceData?.length > 0 ? (
                advaceData.map((item, index) => (
                  <div className="album" key={item.id}>
                    <div className="albumTop">
                      <img src={item.thumbnailUrl} alt="img" className="albumImg" />
                      <div className="albumHover">
                        <div className="albumHoverInner">
                          {/* <div className="favTooltip">
                            <img src={AllImages?.Time} className="albumInnerImg" alt="Time Icon" />
                          </div> */}

                          <div className="favTooltip">
                            <img
                              onClick={() => {
                                handleFavoriteTracks(item, 'albums', item.isFavourite ? 'delete' : 'add');
                              }}
                              src={item.isFavourite ? AllImages.Favorite_sel : AllImages?.Heart}
                              alt="IMG"
                            />
                            <span className="tooltiptext">
                              {!item.isFavourite ? "Mark Favorite" : "Unfavorite"}
                            </span>
                          </div>

                          <div className="favTooltip">
                            <RWebShare
                              data={{
                                url: `${BhaktiPadPlaylistShareUrl}/playlist/${item.id}`,
                                title: "Playlist Share",
                              }}
                              onClick={() => console.log("share")}
                            >
                              <div className="copyTooltip">
                                <img
                                  className="cursor"
                                  src={AllImages.CopyPNG}
                                  alt="Copy"
                                  title="Copy"
                                />
                                <span className="tooltiptext">{"Copy"}</span>
                              </div>
                            </RWebShare>
                          </div>
                        </div>

                        <div className="albumInnerPlayIconOuter"
                          onClick={() => { userDetails?.subscriptionPlan?.subscriptionLevel === 2 ? navigate(`/playlist/${item?.id}`) : takeSubscription() }}>
                          {userDetails?.subscriptionPlan?.subscriptionLevel === 2 ?
                            <div className="bhaktipadPlayIconTooltip">
                              <img src={AllImages?.PlayIconBig} class="albumInnerPlayIcon" alt="img" />
                              <div className="bhaktipadPlayIconTooltipInner">
                                <span className="tooltiptext">
                                  {"Play BhaktiPad Playlist"}
                                </span>
                              </div>
                            </div>
                            :
                            <div className="star1 badgestarInvisible albumLock">
                              <div className="LikeCount badgeShow">
                                <i
                                  className="fa fa-lock"
                                  onClick={() => takeSubscription(item)}
                                ></i>
                              </div>
                              <span className="tooltiptext">
                                {"Subscribe to use this feature"}
                              </span>
                            </div>
                          }
                        </div>

                        {/* <div className="albumInnerPlayIconOuter" onClick={() => navigate(`/playlist/${item?.id}`)}>
                          <img src={AllImages?.PlayIconBig} className="albumInnerPlayIcon" alt="Play Icon" />
                        </div> */}
                      </div>
                    </div>
                    <div className="albumBottom">
                      <p className="name">{item.title}</p>
                      <div className="desc">
                        <p className="time">{convertMinutesToHoursAndMinutes(item?.durationTotalMin) + ' hrs'}</p>
                        <p className="dot">&nbsp;</p>
                        <p className="count"> {item?.totalActiveTracks} bhakti pads</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : dataNoFound ? (
                <NoDataAvailable />
              ) : (
                <div className="loader">
                  <img
                    src={loader}
                    alt="loading..."
                    style={{ height: 35, width: 35, marginTop: 20 }}
                  />
                </div>
              )}
            </div>
          )}

        </div>

        {isShareDialoguOpen && (
          <Dialog
            open={isShareDialoguOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="share-popup"
          >
            <DialogContent
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="share-div">
                <div className="share-image-div">
                  <RWebShare
                    data={{
                      // text: "Like humans, flamingos make friends for life",
                      url: selectedItemToShare.contentUrlApple,

                      title: "Audio Share",
                    }}
                    onClick={() => console.log("share")}
                  >
                    <img
                      src={AllImages?.appleMusicSvg}
                      className="musicIcons read-play-type-btn"
                      alt="Apple Music"
                      title="Apple Music"
                    />
                  </RWebShare>
                  <RWebShare
                    data={{
                      // text: "Like humans, flamingos make friends for life",
                      url: selectedItemToShare.contentUrlSpotify,

                      title: "Audio Share",
                    }}
                  >
                    <img
                      src={AllImages?.spotifyMusicSvg}
                      className="musicIcons read-play-type-btn"
                      alt="Spotify"
                      title="Spotify"
                    />
                  </RWebShare>
                </div>
                <div onClick={() => handleShareDialogue(null)}>
                  <p className="close-sharemodal-button">Close</p>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
        {isOpen && (
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={"paper"}
          >
            <DialogContent
              // ref={dialogContentRef}
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "320px", // Adjust the maximum height as needed
                margin: 5,
              }}
            // onScroll={handlePlaylistScroll}
            >
              <div className="add-my-playlist-modal-content">
                <div className="add-my-playlist-modal-d-flex modal-mb-5">
                  <p className="add-my-playlist-modal-msg-text">
                    {"Add to My Playlists"}
                  </p>
                  <span
                    className="add-my-playlist-modal-close-button"
                    onClick={closeModal}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  {userPlayList &&
                    userPlayList?.map((item, index) => (
                      <div
                        key={item.id}
                        className="add-my-playlist-modal-d-flex modal-mt-15"
                      >
                        <input
                          type="checkbox"
                          id={`checkbox_${item.id}`}
                          name={`checkbox_${item.id}`}
                          data-playlist-id={item.id}
                          checked={isPlaylistSelected(item.id)}
                          onChange={handleCheckboxChange2}
                        />
                        <label
                          className="add-my-playlist-checkbox-text"
                          htmlFor={`checkbox_${item.id}`}
                        >
                          {item.playListName}
                        </label>
                      </div>
                    ))}
                  {userPlayList && userPlayList.length > 6 && scrollHashMap && (
                    <p
                      onClick={fetchMoreUserPlaylistData}
                      className="fetch-more-playlist"
                    >
                      MORE+
                    </p>
                  )}
                </div>
                <div className="modal-mt-15" onClick={createPlaylist}>
                  <label className="add-my-playlist-create-playlist">
                    Create Playlist
                  </label>
                </div>
              </div>
              {isDataLoading && (
                <div className="loader">
                  <img src={loader} alt="loading..." />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <div className="add-my-playlist-modal-action">
                <button
                  onClick={closeModal}
                  className="add-my-playlist-modal-cancel-btn"
                >
                  {"Cancel"}
                </button>
                <button
                  className="add-my-playlist-modal-add-btn"
                  onClick={addVideoAgainstPlaylist}
                >
                  {"confirm"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {showCreatePlayListModel && (
          <Dialog
            open={showCreatePlayListModel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="createPlaylistModal"
          >
            <DialogContent>
              <div className="my-create-playlist-modal-content">
                <div className="modal-d-flex">
                  <p className="my-created-playlist-modal-msg-text">
                    {"Create a Playlist"}
                  </p>
                  <span
                    className="my-created-playlist-modal-close-btn"
                    onClick={() => closeCreatedPlayListModal()}
                  >
                    &times;
                  </span>
                </div>
                <div>
                  <p className="my-create-playlist-modal-playlist-name">
                    {"Playlist Name"}
                  </p>
                  <input
                    placeholder="Spiritual Playlist"
                    className={`f-poppins my-create-playlist-modal-search-input ${isValidPlaylistName ? "invalid" : null
                      } `}
                    type="text"
                    id="playlistName"
                    value={playlistName}
                    onChange={handlePlaylistNameChange}
                  // ref={inputRef} 
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="my-created-playlist-modal-action-content">
                <button
                  className="my-create-playlist-cancel-btn"
                  onClick={() => closeCreatedPlayListModal()}
                >
                  {"Cancel"}
                </button>
                <button
                  className="my-create-playlist-create-btn"
                  onClick={() => handleSubmit()}
                >
                  {createPlaylistBtnLding ? (
                    <img src={loader} style={{ width: 20, height: 20 }} />
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}

        {/* advace search */}
        {isOpenAdvance && (
          <Dialog
            open={openAdvanceModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="filterPopup"
          >
            <DialogContent
              style={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {advaceTabValue == "VIDEO" ? (
                //Video filter
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Video"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Video Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={value}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={inputTitle}
                      onChange={(e) => handleTitle(e)}
                    />
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={fromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            // value={fromDate}
                            // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={fromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={toDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                            // value={toDate}
                            // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* Type */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Type</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={type ? type?.trim() : null}
                          onChange={handleChangeType}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="audio">Audio</MenuItem>
                          <MenuItem value="video">Video</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* //Language */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Language</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filLanguage ? filLanguage?.trim() : null}
                          onChange={(e) => handleChangeLanguages(e)}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi"> हिंदी</MenuItem>
                          <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">speaker</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filterSpeakers ? filterSpeakers?.trim() : null}
                          onChange={handleSpeakers}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={filterlocation ? filterlocation?.trim() : null}
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ) : advaceTabValue == "SYSTEM_PLAYLIST" ? (
                //playlists
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Playlist"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Playlist Duration
                    </p>
                    <Box
                      sx={{
                        width: matchesMD === true ? 310 : 270,
                        paddingLeft: "10px",
                      }}
                    >
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={playlistValue}
                        step={1}
                        marks={[
                          { value: 0, label: "0hr" },
                          { value: 2, label: "2hr" },
                          { value: 4, label: "4hr" },
                          { value: 6, label: "6hr" },
                          { value: 8, label: "8hr" },
                          { value: 10, label: "10hr" },
                        ]}
                        min={1}
                        max={10}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={playListTitle}
                      onChange={(e) => handleTitle(e)}
                    />
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={playListFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            // value={fromDate}
                            // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={playListFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={playListToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                            // value={toDate}
                            // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">speaker</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterPlaylistSpeakers
                              ? filterPlaylistSpeakers?.trim()
                              : null
                          }
                          onChange={handleSpeakers}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterPlayListlocation
                              ? filterPlayListlocation?.trim()
                              : null
                          }
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Upload Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${
                              advaceFilterVal.find(
                                (item) => item.contentType === advaceTabValue
                              )?.uploadDuration == item.value
                                ? "active"
                                : null
                            }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              ) : advaceTabValue == "EBOOK" ? (
                //ebook
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for E-Books"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Pages
                        </p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={ebookValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Author
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          type="text"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookAuthor}
                          onChange={(e) => handleAuthor(e)}
                        />
                        {hasError("author") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("author").message}
                          </p>
                        )}
                      </div>
                      {/* ebookPublisher */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Publisher
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookPublisher}
                          onChange={(e) => handlePublisher(e)}
                        />
                        {hasError("publisher") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("publisher").message}
                          </p>
                        )}
                      </div>
                      {/* ebookVersion */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Version
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookVersion}
                          onChange={(e) => handleVersion(e)}
                        />
                      </div>
                      {/* ebookComments */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Comments
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={ebookComments}
                          onChange={(e) => handleComments(e)}
                        />
                        {hasError("comments") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("comments").message}
                          </p>
                        )}
                      </div>

                      {/* //Language */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Language
                        </p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                ebookLanguage ? ebookLanguage?.trim() : null
                              }
                              onChange={(e) => handleChangeLanguages(e)}
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Hindi"> हिंदी</MenuItem>
                              <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/* ----- Upload Duration ----- */}
                      {/* <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Upload Duration
                        </p>
                        <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                          {uploadDurationArray.map((item) => {
                            return (
                              <div
                                className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                                  (item) =>
                                    item.contentType === advaceTabValue
                                )?.uploadDuration == item.value
                                  ? "active"
                                  : null
                                  }`}
                                onClick={() => uploadDurationselect(item.value)}
                              >
                                <p className="filter-search-modal-today">
                                  {item.lable}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              ) : advaceTabValue == "PATRA" ? (
                //patra
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Patra"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>
                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Pages
                        </p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={patraValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      {/* fromDate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          From Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="fromDate"
                            // minDate={"2022-11-30"}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={patraFromDate}
                            onChange={handleChangeFromDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                // value={fromDate}
                                // onChange={handleChangeFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* todate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          To Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="todate"
                            minDate={patraFromDate}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={patraToDate}
                            onChange={handleChangeToDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="input-font"
                                style={{ width: "100%" }}
                                // value={toDate}
                                // onChange={handleChangeToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/*indian fromdate */}
                      {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar Start
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={indianCalndrFromDate}
                        // onChange={(e) => handleComments(e)}
                      />

                      <DatePicker
                        id="datepicker"
                        value={indianCalndrFromDateObj}
                        className="input-font"
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "from")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}
                      {/*indian todate */}

                      {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar End
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={indianCalndrToDate}
                        // onChange={(e) => handleComments(e)}
                      />
                      <DatePicker
                        id="datepicker"
                        render={<Icon />}
                        value={indianCalndrToDateObj}
                        className="input-font"
                        onChange={(date) => handleHinduCalStart(date, "to")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                      {/* author */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Author
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patraAuthor}
                          onChange={(e) => handleAuthor(e)}
                        />
                        {hasError("author") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("author").message}
                          </p>
                        )}
                      </div>
                      {/* writtenTo */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Written To
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patraWrittenTo}
                          onChange={(e) => handleWrittenTo(e)}
                        />
                      </div>
                      {/* patraStartNumber */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Patra Start No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patrapatraNoStart}
                          onChange={(e) => handlePatraNoStart(e)}
                        />
                        {hasError("patraNoStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoStart").message}
                          </p>
                        )}
                      </div>
                      {/* patraEndINumber */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Patra End No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={patrapatraNoEnd}
                          onChange={(e) => handlePatraNoEnd(e)}
                        />
                        {hasError("patraNoEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoEnd").message}
                          </p>
                        )}
                      </div>

                      {/* Year of patra start */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Year of patra Start
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={yearPatraStart}
                          onChange={(e) => handleYearPatraStart(e)}
                        />
                        {hasError("yearOfPatraStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("yearOfPatraStart").message}
                          </p>
                        )}
                      </div>
                      {/* year of patra end */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Year of Patra End
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={yearPatraEnd}
                          onChange={(e) => handleYearPatraEnd(e)}
                        />
                        {hasError("yearOfPatraEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("yearOfPatraEnd").message}
                          </p>
                        )}
                      </div>

                      {/*----- Upload Duration -----*/}
                      {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                              (item) => item.contentType === advaceTabValue
                            )?.uploadDuration == item.value
                              ? "active"
                              : null
                              }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                    </>
                  )}
                </div>
              ) : advaceTabValue == "PAD" ? (
                //pad
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Pad"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box
                      sx={{
                        width: matchesMD === true ? 310 : 270,
                        paddingLeft: "10px",
                      }}
                    >
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={padValue}
                        step={6}
                        marks={[
                          { value: 1, label: "1m" },
                          { value: 60, label: "60m" },
                          { value: 90, label: "90m" },
                          { value: 120, label: "120m" },
                          { value: 150, label: "150m" },
                          { value: 180, label: "180m" },
                        ]}
                        min={1}
                        max={180}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div>

                  {/* title */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padTitle}
                      onChange={(e) => handleTitle(e)}
                    />
                  </div>
                  {/* author */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padAuthor}
                      onChange={(e) => handleAuthor(e)}
                    />
                    {hasError("author") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("author").message}
                      </p>
                    )}
                  </div>
                  {/* writtenTo */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written To
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={padWrittenTo}
                      onChange={(e) => handleWrittenTo(e)}
                    />
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={padFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            // value={fromDate}
                            // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={padFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={padToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                            // value={toDate}
                            // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar Start
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={padIndianCalndrFromDate}
                        // onChange={(e) => handleComments(e)}
                      />

                      <DatePicker
                        id="datepicker"
                        value={indianCalndrFromDateObj}
                        className="input-font"
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "from")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      India calendar End
                    </p>
                    <div
                      style={{ position: "relative" }}
                      className="indian-calender"
                    >
                      <input
                        className="filter-search-modal-location-div"
                        readOnly
                        style={{
                          fontSize: 17,
                          paddingLeft: 8,
                          width: "100%",
                          boxSizing: "border-box",
                          outline: "none",
                          MozUserFocus: "none",
                        }}
                        value={padIndianCalndrToDate}
                        // onChange={(e) => handleComments(e)}
                      />
                      <DatePicker
                        id="datepicker"
                        value={indianCalndrToDateObj}
                        className="input-font"
                        render={<Icon />}
                        onChange={(date) => handleHinduCalStart(date, "to")}
                        format="DD/MM/YYYY"
                        calendar={indian}
                        locale={indian_hi}
                        maxDate={new Date()}
                        calendarPosition={"left-bottom"}
                      />
                    </div>
                  </div> */}

                  {/* //Language */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Language</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={padLanguage ? padLanguage?.trim() : null}
                          onChange={(e) => handleChangeLanguages(e)}
                        >
                          <MenuItem value="">Select</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                          <MenuItem value="Hindi"> हिंदी</MenuItem>
                          <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {/*----- Upload Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                              (item) => item.contentType === advaceTabValue
                            )?.uploadDuration == item.value
                              ? "active"
                              : null
                              }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </div>
              ) : advaceTabValue == "TRANSCRIPT" ? (
                //patra
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Transcript"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>
                  <div>
                    <p className="toggleText">
                      Enhance Your Search Abilities Within Books
                    </p>
                    <div className="customToggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {isChecked ? (
                    <div className={inputValue ? "" : "epubinvalid"}>
                      <input
                        type="text"
                        className="custom-input"
                        placeholder="Enter text here"
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                    </div>
                  ) : (
                    <>
                      {/*----- Video Duration -----*/}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Pages
                        </p>
                        <Box
                          sx={{
                            width: matchesMD === true ? 310 : 270,
                            paddingLeft: "10px",
                          }}
                        >
                          <Slider
                            sx={{
                              "& .MuiSlider-thumb": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-track": {
                                color: "#FFB200",
                              },
                              "& .MuiSlider-rail": {
                                color: "#212121",
                              },
                              "& .MuiSlider-active": {
                                color: "#212121",
                              },
                            }}
                            valueLabelDisplay="auto"
                            value={transcriptValue}
                            step={6}
                            marks={[
                              { value: 80, label: "80pp" },
                              { value: 160, label: "160pp" },
                              { value: 240, label: "240pp" },
                              { value: 320, label: "320pp" },
                              { value: 400, label: "400pp" },
                              { value: 480, label: "480pp" },
                            ]}
                            min={80}
                            max={480}
                            onChange={onChangeSlider}
                          />
                        </Box>
                      </div>

                      {/* fromDate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          From Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="fromDate"
                            // minDate={"2022-11-30"}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={transcriptFromDate}
                            onChange={handleChangeFromDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: "100%" }}
                                // value={fromDate}
                                // onChange={handleChangeFromDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* todate */}
                      <div className="filter-search-modal-mt-15 customDate">
                        <p className="filter-search-modal-comm-font-13">
                          To Date
                        </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            // label="Date desktop"
                            className="form-control input-font"
                            inputFormat="DD/MM/YYYY"
                            name="todate"
                            minDate={transcriptFromDate}
                            maxDate={new Date().toISOString().slice(0, 10)}
                            value={transcriptToDate}
                            onChange={handleChangeToDate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="input-font"
                                style={{ width: "100%" }}
                                // value={toDate}
                                // onChange={handleChangeToDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>

                      {/* transcriptTopic */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Topic
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptTopic}
                          onChange={(e) => handleTopics(e)}
                        />
                        {hasError("topic") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("topic").message}
                          </p>
                        )}
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Transcript Start No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptPatraNoStart}
                          onChange={(e) => handlePatraNoStart(e)}
                        />
                        {hasError("patraNoStart") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoStart").message}
                          </p>
                        )}
                      </div>

                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Transcript End No.
                        </p>
                        <input
                          className="filter-search-modal-location-div"
                          style={{
                            fontSize: 17,
                            paddingLeft: 8,
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          value={transcriptPatraNoEnd}
                          onChange={(e) => handlePatraNoEnd(e)}
                        />
                        {hasError("patraNoEnd") && (
                          <p className="filter-search-modal-comm-font-10">
                            {hasError("patraNoEnd").message}
                          </p>
                        )}
                      </div>
                      {/* //Language */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          Language
                        </p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                transcriptLanguage
                                  ? transcriptLanguage?.trim()
                                  : null
                              }
                              onChange={(e) => handleChangeLanguages(e)}
                            >
                              <MenuItem value="">Select</MenuItem>
                              <MenuItem value="English">English</MenuItem>
                              <MenuItem value="Hindi"> हिंदी</MenuItem>
                              <MenuItem value="Gujrati">ગુજરાતી</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/* speaker */}
                      <div className="filter-search-modal-mt-15">
                        <p className="filter-search-modal-comm-font-13">
                          speaker
                        </p>
                        <div className="filter-search-modal-location-div">
                          <FormControl
                            fullWidth
                            size="small"
                            style={{ paddingTop: "5px" }}
                          >
                            <Select
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: 0,
                                  },
                                ".MuiSvgIcon-root ": {
                                  fill: "#FFB200 !important",
                                },
                              }}
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={
                                transcriptSpeakers
                                  ? transcriptSpeakers?.trim()
                                  : null
                              }
                              onChange={handleSpeakers}
                            >
                              <MenuItem value="">Select</MenuItem>
                              {satsangSpeaker.map((item) => {
                                return <MenuItem value={item}>{item}</MenuItem>;
                              })}
                              {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                            </Select>
                          </FormControl>
                        </div>
                      </div>

                      {/*----- Upload Duration -----*/}
                      {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Upload Duration
                    </p>
                    <div className="filter-search-modal-d-flex filter-search-modal-mt-5">
                      {uploadDurationArray.map((item) => {
                        return (
                          <div
                            className={`filter-search-modal-upload-dur-div filter-search-modal-mlr-8  ${advaceFilterVal.find(
                              (item) => item.contentType === advaceTabValue
                            )?.uploadDuration == item.value
                              ? "active"
                              : null
                              }`}
                            onClick={() => uploadDurationselect(item.value)}
                          >
                            <p className="filter-search-modal-today">
                              {item.lable}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                    </>
                  )}
                </div>
              ) : advaceTabValue == "EBOOK_SYSTEM_PLAYLIST" ? (
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for ebook series"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/* title */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesTitle}
                      onChange={handleTitle}
                    />
                  </div>
                  {/* author */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesAuthor}
                      onChange={handleAuthor}
                    />
                    {hasError("author") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("author").message}
                      </p>
                    )}
                  </div>
                  {/* writtenTo */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written To
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesWrittenTo}
                      onChange={handleWrittenTo}
                    />
                    {hasError("writtenTo") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("writtenTo").message}
                      </p>
                    )}
                  </div>

                  {/* writtenBy */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Written By
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesWrittenBy}
                      onChange={handleWrittenBy}
                    />
                    {hasError("writtenBy") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("writtenBy").message}
                      </p>
                    )}
                  </div>

                  {/* RecommendedBy */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Recommended By
                    </p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={ebookSeriesRecommendedBy}
                      onChange={handleRecommendedBy}
                    />
                    {hasError("recommendedBy") && (
                      <p className="filter-search-modal-comm-font-10">
                        {hasError("recommendedBy").message}
                      </p>
                    )}
                  </div>

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={ebookSeriesFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                            // value={fromDate}
                            // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={ebookSeriesFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={ebookSeriesToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                            // value={toDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/*----- Location -----*/}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Location</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            filterEbookSerieslocation
                              ? filterEbookSerieslocation?.trim()
                              : null
                          }
                          onChange={handleLocationChange}
                        >
                          <MenuItem value="">Select</MenuItem>
                          {satsangLocations.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ) : advaceTabValue == "YOUTUBE_TRACK" ? (
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Bhakti Pad"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={bhaktiPadDuration}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={bhaktiPadTitle}
                      onChange={handleTitle}
                    />
                  </div> */}

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={bhaktiPadFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* author */}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={bhaktiPadAuthor}
                      onChange={handleAuthor}
                    />
                  </div> */}


                  {/* speaker */}
                  <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Artist</p>
                    <div className="filter-search-modal-location-div">
                      <FormControl
                        fullWidth
                        size="small"
                        style={{ paddingTop: "5px" }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#FFB200 !important",
                            },
                          }}
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={
                            bhaktiPadArtist
                              ? bhaktiPadArtist?.trim()
                              : null
                          }
                          onChange={handleSpeakers}
                        >
                          {hasError("speaker") && (
                            <p className="filter-search-modal-comm-font-10">
                              {hasError("speaker").message}
                            </p>
                          )}
                          <MenuItem value="">Select</MenuItem>
                          {satsangSpeaker.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                          {/* <MenuItem value=''></MenuItem>
                        <MenuItem value='Ahmedabad'>Ahmedabad</MenuItem>
                        <MenuItem value='Mumbai'>Mumbai</MenuItem>
                        <MenuItem value='Pune'>Pune</MenuItem>
                        <MenuItem value='india'>India</MenuItem> */}
                        </Select>
                      </FormControl>
                    </div>
                  </div>


                  {hasError("author") && (
                    <p className="filter-search-modal-comm-font-10">
                      {hasError("author").message}
                    </p>
                  )}
                </div>
              ) : advaceTabValue == "YOUTUBE_ALBUM" ? (
                <div>
                  <div className="filter-search-modal-content">
                    <p className="filter-search-modal-msg-text">
                      {"Filter for Album"}
                    </p>
                    <span
                      className="filter-search-modal-close-button"
                      onClick={() => closeAdvanceModal()}
                    >
                      &times;
                    </span>
                  </div>

                  {/*----- Video Duration -----*/}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">
                      Audio Duration
                    </p>
                    <Box sx={{ width: "92%", paddingLeft: "10px" }}>
                      <Slider
                        sx={{
                          "& .MuiSlider-thumb": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-track": {
                            color: "#FFB200",
                          },
                          "& .MuiSlider-rail": {
                            color: "#212121",
                          },
                          "& .MuiSlider-active": {
                            color: "#212121",
                          },
                        }}
                        valueLabelDisplay="auto"
                        value={albumDuration}
                        step={8}
                        marks={generateDynamicMarksArray(1, 500, 8)}
                        min={1}
                        max={500}
                        onChange={onChangeSlider}
                      />
                    </Box>
                  </div> */}

                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Title</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={albumTitle}
                      onChange={handleTitle}
                    />
                  </div> */}

                  {/* fromDate */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">
                      From Date
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="fromDate"
                        // minDate={"2022-11-30"}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={albumFromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{ width: "100%" }}
                          // value={fromDate}
                          // onChange={handleChangeFromDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* todateI */}
                  <div className="filter-search-modal-mt-15 customDate">
                    <p className="filter-search-modal-comm-font-13">To Date</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        // label="Date desktop"
                        className="form-control input-font"
                        inputFormat="DD/MM/YYYY"
                        name="todate"
                        minDate={albumFromDate}
                        maxDate={new Date().toISOString().slice(0, 10)}
                        value={albumToDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="input-font"
                            style={{ width: "100%" }}
                          // value={toDate}
                          // onChange={handleChangeToDate}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  {/* author */}
                  {/* <div className="filter-search-modal-mt-15">
                    <p className="filter-search-modal-comm-font-13">Author</p>
                    <input
                      className="filter-search-modal-location-div"
                      style={{
                        fontSize: 17,
                        paddingLeft: 8,
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                      value={albumAuthor}
                      onChange={handleAuthor}
                    />
                  </div> */}
                  {hasError("author") && (
                    <p className="filter-search-modal-comm-font-10">
                      {hasError("author").message}
                    </p>
                  )}


                </div>
              ) : null}
            </DialogContent>
            <DialogActions>
              <div className="filter-search-modal-action-content">
                <button
                  className="filter-search-modal-cancel-btn"
                  onClick={() => ClearFilter()}
                >
                  {"Clear"}
                </button>
                <button
                  className="filter-search-modal-apply-btn"
                  onClick={() =>
                    isChecked
                      ? EbookSearchPoc()
                      : advanceSearch(advaceTabValue, 2)
                  }
                >
                  {"Apply"}
                </button>
              </div>
            </DialogActions>
          </Dialog>
        )}
        {/* {openRecaptcha && (
          <Dialog
            open={openRecaptcha}
            className="sortBy captcha"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <ReCAPTCHA
                sitekey={TEST_SITE_KEY}
                onChange={onChange}
                style={{ display: "inline-block" }}
              />
            </DialogContent>
          </Dialog>
        )} */}
      </section>
    </>
  );
}
export default AdvanceSearch;
